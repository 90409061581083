import React from "react";
import {Terminal} from "src/primitives/Terminal/index.jsx";
import {EXTERNAL_LINKS} from "src/links.js";


export function PythonSdk() {
    return (
        <div>
            <div className="flex items-center flex-row-reverse max-sm:flex-col max-sm:gap-4">
                <div className="p-4 flex flex-col gap-3 max-w-96 max-sm:max-w-full max-sm:px-0">
                    <p>
                        We have a Python SDK that is built for dataframes,
                        and with the goal that all common operations are just <strong>one line of code</strong>.
                    </p>
                    <p>
                        See full documentation on
                        <a
                            className="text-blue-500/80 hover:underline pl-1"
                            href={EXTERNAL_LINKS.RHO_DOCS}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {EXTERNAL_LINKS.RHO_DOCS}
                        </a>.
                    </p>
                </div>
                <div className="basis-1/2 max-w-full overflow-x-auto">
                    <Terminal
                        hideTopBar={true}
                        language="python"
                        code={`
# %pip install rho-store
import pandas as pd
from rho_store import RhoClient

client = RhoClient(api_key="$API_KEY")
data = pd.DataFrame({"val": [i for i in range(10)]})

# store DataFrame
table = client.store_df(data)
# visit table in browser
print(table.url)

# append more data to the table
more_data = pd.DataFrame({"val": [2*i for i in range(10)]})
table.append(more_data)

# replace the entire table
new_data = pd.DataFrame({"val": [i for i in range(100)]})
table.replace(new_data)
                        `.trim()}
                    />
                </div>

            </div>
        </div>
    );
}
