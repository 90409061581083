import React, {useCallback, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {arrayToMap} from "src/utils/misc.js";
import {TableHeader} from "src/components/SimpleTable/TableHeader.jsx";
import {TableFooter} from "src/components/SimpleTable/TableFooter.jsx";
import {TablePagination} from "src/components/SimpleTable/TablePagination.jsx";
import {smartSorting} from "src/utils/sorting.js";
import {Table, Tbody, Td, Tr} from "./components.jsx";
import {nextDirection} from "./utils.jsx";


function getPaginatedData(data, maxRows, currentPage) {
    if (!maxRows) {
        return data;
    }
    const firstIndex = (currentPage - 1) * maxRows;
    const lastIndex = firstIndex + maxRows;
    return data.slice(firstIndex, lastIndex);
}


export function SimpleTable({data, headers, footers, columns, maxRows, onRowClick}) {
    const [currentPage, setPage] = useState(1);
    const [sorting, setSorting] = useState(null);

    const handleSort = useCallback((key) => {
        setSorting((prevValue) => {
            if (prevValue?.key === key) {
                return {key, direction: nextDirection(prevValue.direction)};
            }
            return {key, direction: "asc"};
        });
    }, [setSorting]);

    const getSortStatus = (key) => {
        if (key === sorting?.key) {
            return sorting.direction;
        }
        return null;
    };

    if (columns) {
        // eslint-disable-next-line no-param-reassign
        headers = columns.map(({header}) => header);
        // eslint-disable-next-line no-param-reassign
        footers = columns.map(({footer}) => footer);
    } else {
        // eslint-disable-next-line no-param-reassign
        columns = Object.keys(data[0]).map((key) => ({key}));
    }

    const keys = columns.map(({key}) => key);
    const columnMap = arrayToMap(columns, "key");
    const formatters = useMemo(() => {
        return columns.filter(({key, render}) => key && render).reduce((acc, item) => {
            return Object.assign({}, acc, {[item.key]: item.render});
        }, {});
    }, [columns]);

    const hasHeader = useMemo(() => {
        if (!Array.isArray(headers)) {
            return false;
        }
        const existingHeaders = headers.filter((value) => !!value);
        return existingHeaders.length > 0;
    }, [headers]);

    const hasFooter = useMemo(() => {
        if (!Array.isArray(footers)) {
            return false;
        }
        const existingFooters = footers.filter((value) => !!value);
        return existingFooters.length > 0;
    }, [footers]);

    const getCellStyle = useCallback((key, value) => {
        const columnObject = columnMap[key];
        if (columnObject.getStyle) {
            return columnObject.getStyle(value);
        }

        return null;
    }, [columnMap]);

    const getValue = useCallback((key, row) => {
        const columnObject = columnMap[key];
        if (columnObject.getValue) {
            return columnObject.getValue(row);
        }
        return row[key];
    }, [columnMap]);

    const extendedData = useMemo(() => {
        return data.map((row) => {
            const extendedFields = columns.reduce((acc, column) => {
                if (column.getValue) {
                    return Object.assign(acc, {[column.key]: column.getValue(row)});
                }
                return acc;
            }, {});
            return {...row, ...extendedFields};
        });
    }, [data, columns]);

    const sortedData = useMemo(() => {
        if (sorting && sorting.direction) {
            return smartSorting(extendedData, sorting.key, sorting.direction);
        }
        // default
        return smartSorting(extendedData, columns[0].key, "asc");
    }, [extendedData, sorting, columns]);

    const paginatedData = getPaginatedData(sortedData, maxRows, currentPage);

    const handleRowClick = useCallback((row) => {
        if (onRowClick) {
            onRowClick(row);
        }
    }, [onRowClick]);

    return (
        <>
            <Wrapper>
                <TableContainer>
                    <Table>
                        {hasHeader && (
                            <TableHeader
                                keys={keys}
                                headers={headers}
                                handleSort={handleSort}
                                getSortStatus={getSortStatus}
                            />
                        )}

                        <Tbody>
                            {paginatedData.map((row, i) => (
                                <Tr
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`row-${i}`}
                                    onClick={handleRowClick.bind(null, row)}
                                    $isClickable={!!onRowClick}
                                >
                                    {keys.map((key) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <Td key={`row-${i}-${key}`} style={getCellStyle(key, getValue(key, row))}>
                                            {formatters[key]
                                                ? formatters[key](getValue(key, row), row)
                                                : getValue(key, row)}
                                        </Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tbody>

                        {hasFooter && (
                            <TableFooter keys={keys} footers={footers}/>
                        )}
                    </Table>
                </TableContainer>
            </Wrapper>
            {(maxRows && maxRows < data.length) && (
                <TablePagination
                    currentPage={currentPage}
                    totalRows={data.length}
                    pageSize={maxRows}
                    onChange={setPage}
                />
            )}
        </>
    );
}

SimpleTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    headers: PropTypes.arrayOf(PropTypes.string),
    footers: PropTypes.arrayOf(PropTypes.string),
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        header: PropTypes.node,
        render: PropTypes.func,
        footer: PropTypes.node,
        getStyle: PropTypes.func
    })),
    maxRows: PropTypes.number,
    onRowClick: PropTypes.func
};

SimpleTable.defaultProps = {
};


const Wrapper = styled.div`
  margin: 10px 0;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const TableContainer = styled.div`
  display: block;
  white-space: nowrap;
  max-width: 100%;
`;
