import React from "react";
import PropTypes from "prop-types";
import {SimpleTable} from "src/components/SimpleTable/index.jsx";


export function ResidualsTable({residuals}) {
    return (
        <div>
            <SimpleTable data={residuals}/>

        </div>
    );
}
ResidualsTable.propTypes = {
    residuals: PropTypes.arrayOf(PropTypes.object)
};
