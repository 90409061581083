import React from "react";
import PropTypes from "prop-types";
import {useDrag, useDrop} from "react-dnd";


export function SortableListItem({id, text, index, moveCard, onDrop}) {
    const ref = React.useRef(null);

    // Drop functionality
    const [, drop] = useDrop({
        accept: "CARD",
        hover: (item) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Move the card
            moveCard(dragIndex, hoverIndex);

            // Update the index for the dragged item
            // eslint-disable-next-line no-param-reassign
            item.index = hoverIndex;
        },
        drop: (item) => {
            if (onDrop) {
                onDrop(item);
            }
        }
    });

    // Drag functionality
    const [{isDragging}, drag] = useDrag({
        type: "CARD",
        item: () => {
            return {id, index};
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    // Combine drag and drop refs
    drag(drop(ref));

    return (
        <div
            ref={ref}
            className={`p-4 mb-2 border rounded ${
                isDragging ? "opacity-50" : "opacity-100"
            } bg-white shadow-md`}
        >
            {text}
        </div>
    );
}

SortableListItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    index: PropTypes.number,
    moveCard: PropTypes.func,
    onDrop: PropTypes.func
};

export const ListItemMemo = React.memo(SortableListItem);
