import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {LogoImg, LogoText} from "src/primitives/Logo/index.jsx";
import {LINKS} from "src/links.js";
import {Button} from "src/primitives/button.jsx";
import {useAuth} from "@clerk/clerk-react";
import {Badge} from "src/primitives/badge.jsx";
import {HamburgerMenu} from "./components/HamburgerMenu";


export function WebHeader() {
    const {isSignedIn} = useAuth();

    const menuItems = [{
        link: LINKS.SIGN_IN,
        title: "Sign in"
    }, {
        link: LINKS.SIGN_UP,
        title: "Sign up"
    }];

    return (
        <Wrapper
            className="py-2 px-4 flex items-center justify-between"
        >
            <div className="flex items-center gap-2">
                <LogoImg/>
                <div className="text-white">
                    <LogoText link={LINKS.WEB}/>
                </div>
                <div className="mx-1">
                    <Badge size="small" theme="orangeOutline">beta</Badge>
                </div>
            </div>

            <div className="gap-4 flex items-center max-sm:hidden">
                {isSignedIn ? (
                    <>
                        <Link to={LINKS.HOME}>
                            <Button theme="white">
                                Open app
                            </Button>
                        </Link>
                        <Link to={LINKS.SIGN_OUT}>
                            <Button theme="outline">
                                Sign out
                            </Button>
                        </Link>
                    </>
                ) : (
                    <>
                        <Link to={LINKS.SIGN_IN}>
                            <Button theme="white">
                                Sign in
                            </Button>
                        </Link>
                        <Link to={LINKS.SIGN_UP}>
                            <Button theme="outline">
                                Sign up
                            </Button>
                        </Link>
                    </>
                )}
            </div>
            <div className="hidden max-sm:block">
                <HamburgerMenu items={menuItems}/>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div``;
