import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";


export const GET_FITTED_DISTRIBUTIONS = gql`
query GetFittedDistributions($id: String!, $version: Int) {
  table (tableId: $id) {
    id
    fittedDistributions(version: $version)
  }
}
`;


export function useGetFittedDistributions(tableId, version) {
    const options = {variables: {id: tableId, version}};
    const {data, loading, error, refetch} = useQuery(GET_FITTED_DISTRIBUTIONS, options);

    const distributions = useMemo(() => {
        if (!data?.table?.fittedDistributions) {
            return {};
        }

        const fd = data.table.fittedDistributions;
        window.fd = fd;
        return fd;
    }, [data]);

    return {
        data: distributions, loading, error, refetch
    };
}
