import React, {JSX} from "react";
import {Terminal} from "src/primitives/Terminal";


export function RetrieveExample(): JSX.Element {
    return (
        <div>
            <Terminal
                hideTopBar={true}
                language="python"
                code={`
from rho_store import RhoClient

client = RhoClient(api_key="$API_KEY")

table_id = "folder/your_table_name"
table = client.get_table(table_id)

# fetch entire table as a dataframe
df = table.get_df()

# create a filter
df = table.get_df(filters=[("column", ">=", 5)])
                        `.trim()}
            />
        </div>
    );
}
