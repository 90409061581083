import React, {JSX} from "react";
import {ColumnStats, TableColumn, TableVersionStats} from "src/types";
// eslint-disable-next-line max-len
import {
    ColumnStatsTable
} from "src/pages/Home/pages/TablePage/components/ColumnDetailsSidebar/components/ColumnDetails/components/ColumnStats";


interface ColumnStatsVersionOverviewProps {
    column: TableColumn;
    allVersionStats: TableVersionStats[];
}


function extractColumnStats(column: TableColumn, allVersionStats: TableVersionStats[]): ColumnStats[] {
    return allVersionStats.map((item: TableVersionStats) => {
        return item.data[column.name];
    });
}


export function ColumnStatsVersionOverview({
    column,
    allVersionStats
}: ColumnStatsVersionOverviewProps): JSX.Element {
    const columnStats = extractColumnStats(column, allVersionStats);

    const versionHeaders = allVersionStats.map((item) => `Version ${item.version}`);
    const headers = ["", ...versionHeaders];

    return (
        <div>
            <ColumnStatsTable
                column={column}
                columnStats={columnStats}
                headers={headers}
            />
        </div>
    );
}
