import React from "react";
import PropTypes from "prop-types";
import {Title} from "src/primitives/title.jsx";
import {
    isColumnBoolean,
    isColumnCategorical,
    isColumnConstant,
    isColumnContinuous,
    isColumnDate,
    isColumnIdentifier,
    isColumnText
} from "src/utils/tableData.js";
import {BoxPlot} from "src/charts/BoxPlot/index.jsx";
import {WordCloud} from "src/charts/WordCloud/index.jsx";
import {ColumnFittedDistributions} from "./components/ColumnFittedDistributions/index.jsx";
import {ColumnDatesDistribution} from "./components/ColumnDatesDistribution/index.jsx";
import {ColumnBooleanValues} from "./components/ColumnBooleanValues/index.jsx";
import {ColumnCategoricalValues} from "./components/ColumnCategoricalValues/index.jsx";
import {ColumnCorrelations} from "./components/ColumnCorrelations/index.jsx";
import {ColumnStatsTable} from "./components/ColumnStats";
import {ColumnDistribution} from "./components/ColumnDistribution/index.jsx";
import {ColumnValues} from "./components/ColumnValues/index.jsx";
import {ColumnAggregation} from "./components/ColumnAggregations/index.jsx";
import {Divider} from "./Divider.jsx";


const SectionDivider = ({children}) => (
    <div className="mb-6">
        {children}
    </div>
);

SectionDivider.propTypes = {
    children: PropTypes.node
};

export function ColumnDetails({column, correlations, values, stats, distribution, fittedDistributions, textAnalysis}) {
    const showBoxPlot = false;

    return (
        <div>
            {(isColumnContinuous(column) && distribution) && (
                <>
                    <SectionDivider>
                        <Title size="xs">
                            Distribution
                        </Title>
                        <Divider/>
                        <div className="mt-2 mx-[-8px]">
                            <ColumnDistribution distribution={distribution}/>
                        </div>
                    </SectionDivider>

                    <SectionDivider>
                        <Title size="xs">
                            Theoretical distribution fit
                        </Title>
                        <Divider/>
                        <ColumnFittedDistributions fittedDistributions={fittedDistributions}/>
                    </SectionDivider>

                    {showBoxPlot && (
                        <SectionDivider>
                            <Title size="xs">
                                Boxplot
                            </Title>
                            <div className="w-full h-1 my-0.5"/>
                            <div>
                                <BoxPlot data={stats} width={480}/>
                            </div>
                        </SectionDivider>
                    )}
                </>
            )}
            {(isColumnContinuous(column) && isColumnDate(column)) && (
                <SectionDivider>
                    <Title size="xs">
                        Distribution
                    </Title>
                    <Divider/>
                    <ColumnDatesDistribution
                        name={column.name}
                        values={values}
                    />
                </SectionDivider>
            )}
            {isColumnBoolean(column) && (
                <SectionDivider>
                    <Title size="xs">
                        Distribution
                    </Title>
                    <Divider/>
                    <ColumnBooleanValues values={values}/>
                </SectionDivider>
            )}
            {(isColumnContinuous(column) && correlations) && (
                <SectionDivider>
                    <Title size="xs">
                        Correlations
                    </Title>
                    <Divider/>
                    <ColumnCorrelations correlations={correlations}/>
                </SectionDivider>
            )}

            <SectionDivider>
                <Title size="xs">
                    Statistics
                </Title>
                <Divider/>
                <div className="max-w-96">
                    <ColumnStatsTable column={column} columnStats={[stats]}/>
                </div>
            </SectionDivider>

            {(isColumnText(column, true) || isColumnIdentifier(column)) && (
                <>
                    <SectionDivider>
                        <Title size="xs">
                            Sample values
                        </Title>
                        <Divider/>
                        <ColumnValues values={values}/>
                    </SectionDivider>
                    {textAnalysis && (
                        <SectionDivider>
                            <Title size="xs">
                                Word frequency
                            </Title>
                            <div className="max-w-128 mt-[-8px] mb-2">
                                <WordCloud data={textAnalysis}/>
                            </div>
                        </SectionDivider>
                    )}
                </>
            )}

            {isColumnCategorical(column) && (
                <>
                    <SectionDivider>
                        <Title size="xs">
                            Values
                        </Title>
                        <Divider/>
                        <ColumnCategoricalValues values={values}/>
                    </SectionDivider>

                    <SectionDivider>
                        <Title size="xs">
                            Aggregation
                        </Title>
                        <ColumnAggregation
                            tableId={column.tableId}
                            columnName={column.name}
                        />
                    </SectionDivider>
                </>
            )}
            {(isColumnConstant(column) && values[0]) && (
                <SectionDivider>
                    <Title size="xs">
                        Values
                    </Title>
                    <Divider/>
                    <div className="text-xs monospace py-4">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        All rows are "<span className="font-bold">{values[0].name}</span>".
                    </div>
                </SectionDivider>
            )}
        </div>
    );
}

ColumnDetails.propTypes = {
    column: PropTypes.object,
    correlations: PropTypes.object,
    values: PropTypes.array,
    stats: PropTypes.object,
    distribution: PropTypes.arrayOf(PropTypes.object),
    fittedDistributions: PropTypes.arrayOf(PropTypes.object),
    textAnalysis: PropTypes.arrayOf(PropTypes.object)
};
