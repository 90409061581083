import {useCallback, useMemo} from "react";
import {useSearchParams} from "react-router-dom";
import {getUrlParams} from "src/utils/urlParams.js";

export const WORKSPACE_PARAM_KEY = "wid";
export const WORKSPACE_HEADER_KEY = "X-Workspace-ID";

export function useSelectedWorkspace() {
    const [searchParams, setSearchParams] = useSearchParams();
    const workspaceId = searchParams.get(WORKSPACE_PARAM_KEY);

    const setWorkspaceId = useCallback((wid) => {
        setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            params.set(WORKSPACE_PARAM_KEY, wid);
            return params;
        });
    }, [setSearchParams]);

    const workspaceIdMemo = useMemo(() => workspaceId, [workspaceId]);

    return [workspaceIdMemo, setWorkspaceId];
}

export function getWorkspaceId() {
    const params = getUrlParams();

    return params[WORKSPACE_PARAM_KEY];
}
