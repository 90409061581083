import React, {useEffect} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useGetTableStats} from "src/api/tableData/useGetTableStats.js";
import {TableGrid} from "src/components/TableGrid/index.jsx";
import {CenteredContent} from "src/primitives/layout/index.jsx";
import {Spinner} from "src/primitives/spinner.jsx";
import {
    DistributionsContextProvider
} from "src/pages/Home/pages/TablePage/pages/TableGridPage/distribution_context.jsx";
import {TableUpdates} from "src/pages/Home/pages/TablePage/pages/TableGridPage/TableUpdates.jsx";
import {TriggerEvent} from "src/api/events/TriggerEvent.jsx";
import {EVENTS} from "src/api/events/events.js";
import {useGetTablePreview} from "src/api/tableData/useGetTablePreview.js";
import {useSelectedTableVersion} from "src/utils/urlVariables/tableVersion.js";
import {TableDataContextProvider, useLoadTableData} from "./table_data_context.jsx";
import {TableGridToolbar} from "./components/TableGridToolbar/index.jsx";


export function TableGridPage({tableId, table}) {
    const [tableVersion] = useSelectedTableVersion();
    const {data: tablePreview, loading: loadingTablePreview} = useGetTablePreview(tableId, tableVersion);
    const {data: tableStats, loading: loadingTableStats} = useGetTableStats(tableId, tableVersion);
    const [{loadData}, {data, loading}] = useLoadTableData();

    useEffect(() => {
        loadData();
    }, [loadData]);

    if (loadingTablePreview || loadingTableStats) {
        return (
            <CenteredContent>
                <Spinner size="xl" theme="blue" speed={1.2}/>
            </CenteredContent>
        );
    }

    if (!tableStats) {
        return <CenteredContent>Failed to load table stats</CenteredContent>;
    }

    if (!tablePreview) {
        return <CenteredContent>Failed to load column values</CenteredContent>;
    }

    return (
        <Wrapper>
            <TriggerEvent
                name={EVENTS.TABLE_VIEWED}
                tableId={table.id}
            />
            <TableGridToolbar
                table={table}
            />
            <TableGrid
                data={data}
                loadingData={loading}
                table={table}
                tableStats={tableStats}
                tablePreview={tablePreview}
            />
            <TableUpdates data={data}/>
        </Wrapper>
    );
}

TableGridPage.propTypes = {
    tableId: PropTypes.string,
    table: PropTypes.object
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;


export function TableGridPageWrapper({tableId, table}) {
    return (
        <TableDataContextProvider key={tableId} tableId={tableId}>
            <DistributionsContextProvider columns={table.columns} tableId={tableId}>
                <TableGridPage key={tableId} tableId={tableId} table={table}/>
            </DistributionsContextProvider>
        </TableDataContextProvider>
    );
}

TableGridPageWrapper.propTypes = {
    tableId: PropTypes.string,
    table: PropTypes.object
};
