import React from "react";
import {MarkdownContent} from "src/primitives/Markdown/index.jsx";
import {PageWrapper} from "src/pages/Web/components/layouts.jsx";
import {PageMeta} from "src/pages/Web/components/PageMeta";
// @ts-ignore
import content from "./content.md?raw";


export function FitDistributionPage() {
    return (
        <>
            <PageMeta
                title="rho.store - fit distributions"
                description={`
                How to fit distributions to your data.
                `}
            />
            <PageWrapper>
                <MarkdownContent content={content}/>
            </PageWrapper>
        </>
    );
}
