import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {FiChevronRight, FiChevronsRight} from "react-icons/fi";
import {Skeleton} from "@chakra-ui/react";
import {LINKS} from "src/links.js";
import {useGetLink} from "src/utils/useGetLink.js";
import {Title} from "src/primitives/title.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {useGetAllResources} from "src/api/useGetAllResources.js";
import {arrayToMap} from "src/utils/misc.js";
import {useReadTable} from "src/api/tables/useGetTables.js";
import {isLocal} from "src/config.js";
import {EditTableName} from "./EditTableName.jsx";
import {SelectTableVersion} from "./components/SelectTableVersion/index.jsx";


export function TablePageHeader({tableId, workspaceId}) {
    const getLink = useGetLink();
    const {table, exists} = useReadTable(tableId);
    const {folders, loading: loadingFolders} = useGetAllResources(workspaceId);
    const foldersMap = arrayToMap(folders, "id");

    if (!exists || loadingFolders) {
        return (
            <Skeleton className="w-56">
                <span>
                    Loading
                </span>
            </Skeleton>
        );
    }

    const folder = table.folderId && foldersMap[table.folderId];

    return (
        <div className="flex items-center gap-0">
            {folder && (
                <>
                    {folder.parentFolderId && (
                        <IconWrapper
                            size="small"
                            icon={<FiChevronsRight/>}
                            thickness={1.5}
                        />
                    )}
                    <Title size="xs">
                        <Link to={getLink(LINKS.folderPage(folder.id))} className="hover:underline">
                            {folder.name}
                        </Link>
                    </Title>
                    <IconWrapper
                        size="small"
                        icon={<FiChevronRight/>}
                        thickness={1.5}
                    />
                </>
            )}
            <Title size="xs">
                <EditTableName
                    tableId={tableId}
                    name={table?.name}
                />
            </Title>

            {isLocal() && (
                <SelectTableVersion tableId={tableId}/>
            )}
        </div>
    );
}

TablePageHeader.propTypes = {
    tableId: PropTypes.string.isRequired,
    workspaceId: PropTypes.string.isRequired
};
