import React from "react";
import styled from "styled-components";
import {Title} from "src/primitives/title.jsx";
import {Link} from "react-router-dom";
import {EXTERNAL_LINKS, LINKS} from "src/links.js";
import {LinkList} from "src/pages/Web/sections/Footer/LinkList.jsx";


export function WebFooter() {
    const articles = [{
        url: "fit-distributions",
        title: "Fit distributions"
    }, {
        url: "save-pandas-dataframe",
        title: "Save Pandas Dataframe"
    }, {
        url: "csv-to-api",
        title: "CSV to API"
    }];

    const legal = [{
        url: "privacy",
        title: "Privacy Policy"
    }, {
        url: "terms",
        title: "Terms of service"
    }, {
        url: "sub-processors",
        title: "Sub-processors"
    }];
    const resources = [{
        url: EXTERNAL_LINKS.RHO_DOCS,
        title: "Documentation"
    }, {
        url: EXTERNAL_LINKS.RHO_STATUS_PAGE,
        title: "Status page"
    }];

    return (
        <Wrapper
            className="py-8 px-4 mb-4 flex max-sm:flex-col max-sm:gap-4"
        >
            <div className="basis-1/4">
                <Title size="xs">
                    &copy;&nbsp;
                    <span className="monospace hover:underline">
                        <Link to={LINKS.WEB}>
                            rho.store
                        </Link>
                    </span>
                </Title>
            </div>
            <div className="basis-1/4">
                <Title size="xs" style={{margin: 0}}>
                    Legal
                </Title>
                <LinkList links={legal}/>
            </div>
            <div className="basis-1/4">
                <Title size="xs" style={{margin: 0}}>
                    Resources
                </Title>
                <LinkList links={resources}/>
            </div>
            <div className="basis-1/4">
                <Title size="xs" style={{margin: 0}}>
                    Articles
                </Title>
                <LinkList links={articles}/>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
`;
