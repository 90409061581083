import React from "react";
import PropTypes from "prop-types";
import {Versioning} from "src/pages/Web/pages/Start/components/FeaturesOverview/features/Versioning.jsx";
import {Features} from "../content.js";
import {PythonSdk} from "./PythonSdk.jsx";
import {AutomatedAnalysis} from "./AutomatedAnalysis.jsx";
import {Collaboration} from "./Collaboration.jsx";


export function FeatureContent({featureKey}) {
    if (featureKey === Features.PYTHON_SDK) {
        return <PythonSdk/>;
    }

    if (featureKey === Features.AUTOMATED_ANALYSIS) {
        return <AutomatedAnalysis/>;
    }

    if (featureKey === Features.COLLABORATION) {
        return <Collaboration/>;
    }

    if (featureKey === Features.VERSIONING) {
        return <Versioning/>;
    }

    return (
        <div>
            {featureKey}
        </div>
    );
}

FeatureContent.propTypes = {
    featureKey: PropTypes.oneOf(Object.values(Features))
};
