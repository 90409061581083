import {useCallback, useMemo} from "react";
import {useSearchParams} from "react-router-dom";
import {getUrlParams} from "src/utils/urlParams.js";
import {tryParseInt} from "src/utils/formatting.js";

export const TABLE_VERSION_PARAM_KEY = "version";
export const TABLE_VERSION_HEADER_KEY = "X-Table-Version";

export function useSelectedTableVersion() {
    const [searchParams, setSearchParams] = useSearchParams();
    const tableVersion = searchParams.get(TABLE_VERSION_PARAM_KEY);

    const setTableVersion = useCallback((version) => {
        setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            params.set(TABLE_VERSION_PARAM_KEY, version);
            return params;
        });
    }, [setSearchParams]);

    const tableVersionMemo = useMemo(() => {
        return tryParseInt(tableVersion);
    }, [tableVersion]);

    return [tableVersionMemo, setTableVersion];
}

export function getTableVersion() {
    const params = getUrlParams();

    return params[TABLE_VERSION_PARAM_KEY];
}
