import React from "react";
import PropTypes from "prop-types";
import {useGetColumns} from "src/api/tableColumns/useGetColumns.js";
import {Sortable} from "src/primitives/Sortable/index.jsx";
import {ColumnItem} from "./components/ColumnItem/index.jsx";


export function EditColumns({tableId}) {
    const {columns, loading} = useGetColumns(tableId);

    if (loading) {
        return (
            <div>Loading...</div>
        );
    }

    const items = columns.map(({id, name}) => ({id, text: name}));

    return (
        <div>
            <Sortable items={items} />

            <hr className="my-4"/>
            {columns.map((column) => (
                <ColumnItem
                    key={column.id}
                    tableId={tableId}
                    column={column}
                />
            ))}
        </div>
    );
}

EditColumns.propTypes = {
    tableId: PropTypes.string.isRequired
};
