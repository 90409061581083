import React from "react";
import styled from "styled-components";
import {formatInteger, formatNumber, formatPercent, formatTimestamp} from "src/utils/formatting.js";
import {isColumnContinuous, isColumnDate, isColumnInteger, isColumnNumeric} from "src/utils/tableData.js";
import {ColumnStats, TableColumn} from "src/types";
import {Divider} from "../../Divider.jsx";


const basicStats = [{
    name: "Table rows",
    key: "rows",
    render: formatInteger
}, {
    name: "Total values",
    key: "values",
    render: formatInteger
}, {
    name: "Total values (%)",
    key: "valuesPercent",
    render: formatPercent
}, {
    name: "Missing/Null",
    key: "nulls",
    render: formatInteger
}, {
    name: "Missing/Null (%)",
    key: "nullsPercent",
    render: formatPercent
}, {
    name: "Unique values",
    key: "distinct",
    render: formatInteger
}, {
    name: "Unique values (%)",
    key: "distinctPercent",
    render: formatPercent
}];


interface ColumnStatsProps {
    column: TableColumn;
    columnStats: ColumnStats[];
    headers?: string[];
}

function augmentStats(stats: ColumnStats): object {
    return Object.assign({}, stats, {
        valuesPercent: stats.values / stats.rows,
        nullsPercent: stats.nulls / stats.rows,
        distinctPercent: stats.distinct / stats.rows
    });
}

function renderValue(value: string, render?: (v: string) => string) {
    if (render) {
        return render(value);
    }

    // default fallback
    return formatNumber(value);
}

export function ColumnStatsTable({column, columnStats, headers}: ColumnStatsProps) {
    let config;

    const augmentedStats = columnStats.map(augmentStats);

    if (isColumnContinuous(column) && isColumnNumeric(column)) {
        const render = isColumnInteger(column) ? formatInteger : formatNumber;
        config = [
            ...basicStats,
            {name: "DIVIDER", key: "divider-1"},
            {name: "Average", key: "avg"},
            {name: "Standard Deviation", key: "sd"},
            {name: "DIVIDER", key: "divider-2"},
            {name: "Min", key: "min", render},
            {name: "Percentile 1", key: "p1", render},
            {name: "Percentile 5", key: "p5", render},
            {name: "Percentile 25 (Q1)", key: "p25", render},
            {name: "Percentile 50 (Median)", key: "p50", render},
            {name: "Percentile 75 (Q3)", key: "p75", render},
            {name: "Percentile 95", key: "p95", render},
            {name: "Percentile 99", key: "p99", render},
            {name: "Max", key: "max", render}
        ];
    } else if (isColumnDate(column)) {
        const dateStats = [{
            name: "Min",
            key: "min",
            render: (v: string) => formatTimestamp(v)
        }, {
            name: "Max",
            key: "max",
            render: (v: string) => formatTimestamp(v)
        }];
        config = [
            ...basicStats,
            {name: "DIVIDER", key: "divider"},
            ...dateStats
        ];
    } else {
        config = basicStats.slice();
    }

    const valuesWrapperStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(${columnStats.length}, 120px)`
    };

    return (
        <div className="text-xs monospace">
            {(headers && headers.length > 0) && (
                <div>

                    <div
                        className="flex justify-between text-neutral-900"
                    >
                        <Definition
                            className="text-neutral-800 basis-[180px]"
                        >
                            {headers[0]}
                        </Definition>

                        <div style={valuesWrapperStyle}>
                            {headers.slice(1).map((header, index) => (
                                <div key={header} className="text-right">
                                    {header}
                                </div>
                            ))}
                        </div>
                    </div>
                    <Divider/>
                </div>
            )}

            <div className="my-2 -ml-2 flex flex-col">
                {config.map((item) => {
                    if (item.name === "DIVIDER") {
                        return (
                            <Divider key={item.key}/>
                        );
                    }

                    // @ts-ignore
                    return (
                        <div
                            key={item.name}
                            className="flex justify-between py-1 px-2 rounded-md hover:bg-black/5"
                        >
                            <Definition
                                className="text-neutral-800 basis-[180px]"
                            >
                                {item.name}
                            </Definition>
                            <div style={valuesWrapperStyle}>
                                {augmentedStats.map((statsData, index) => (
                                    <Value
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`${item.key}-${index}`}
                                        className="text-neutral-950 text-right"
                                    >
                                        {/* @ts-ignore */}
                                        {renderValue(statsData[item.key], item.render)}
                                    </Value>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}


const Definition = styled.span`
  font-weight: 600;
  :after {
    content: ":";
  }
`;

const Value = styled.span`
`;
