import React from "react";
import {Image} from "src/pages/Web/components/Image.jsx";
import analysisAggregation from "./assets/aggregation.png";
import analysisCorrelation from "./assets/correlation.png";


export function AutomatedAnalysis() {
    return (
        <div className="mx-8">
            <div className="flex flex-col">
                <div className="mb-8 w-96 mx-auto max-w-full">
                    <p>
                        Uploaded datasets are analyzed instantly,
                        offering quick insights and allowing you to explore your data more thoroughly and efficiently.
                    </p>
                </div>

                <div className="flex gap-4 max-sm:flex-col">
                    <div className="basis-1/2 flex flex-col justify-between">
                        <div className="h-56">
                            <Image source={analysisAggregation} bgSize="cover"/>
                        </div>
                        <div className="text-sm monospace my-4 text-white/80 text-center">
                            <p>Aggregations</p>
                        </div>
                    </div>
                    <div className="basis-1/2 flex flex-col justify-between">
                        <div className="h-56">
                            <Image source={analysisCorrelation} bgSize="cover"/>
                        </div>
                        <div className="text-sm monospace my-4 text-white/80 text-center">
                            <p>Correlation matrix</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
