import React, {JSX} from "react";
import {Helmet} from "react-helmet-async";


interface PageMetaProps {
    title: string;
    description?: string;
}

export function PageMeta({title, description}: PageMetaProps): JSX.Element {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            {description && (
                <meta name="description" content={description} />
            )}
        </Helmet>
    );
}
