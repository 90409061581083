import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";


export function HeroTitle({children}) {
    return (
        <Wrapper className="my-2">
            <h1 className="block font-semibold text-white text-2xl md:text-3xl lg:text-4xl">
                {children}
            </h1>
        </Wrapper>
    );
}

HeroTitle.propTypes = {
    children: PropTypes.node
};

const Wrapper = styled.div`
  background: linear-gradient(to right bottom, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0.48));
  background-clip: text;
  -webkit-box-decoration-break: clone;
  -webkit-text-fill-color: transparent;
`;
