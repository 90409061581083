import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

const GET_WORKSPACE_USAGE_QUERY = gql`
    query GetWorkspace ($id: String) {
        workspace (workspaceId: $id) {
            id
            usage {
                totalTables
                totalBytes
            }
        }
    }
`;

export function useGetWorkspaceUsage(workspaceId) {
    const options = {variables: {id: workspaceId}};
    const {data, loading, error, refetch} = useQuery(GET_WORKSPACE_USAGE_QUERY, options);

    const workspace = useMemo(() => {
        return data?.workspace;
    }, [data]);

    return {
        workspace, loading, error, refetch
    };
}
