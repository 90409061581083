import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "./styles.css";
import "./table_style.css";
import {getGridTemplate, useColumnWidth} from "./columnWidth.js";
import {registerEventListeners, removeEventListeners} from "./navigation.js";
import {getCellClass} from "./utils.js";
import {RowId} from "./RowId/index.jsx";
import {Cell} from "./Cell/index.jsx";
import {TableGridFooter} from "./Footer/index.jsx";
import {TableGridHeader} from "./Header/index.jsx";
import {LoadingOverlay} from "./Loader.jsx";


export function TableGrid(props) {
    const ref = useRef();
    const {
        table,
        tableStats,
        tablePreview,
        data,
        loadingData
    } = props;

    const visibleColumns = table.columns.filter((column) => {
        return !column.isHidden;
    }).map(({name}) => name);

    const [columnWidths, updateColumnWidth] = useColumnWidth(table.id);
    const gtc = getGridTemplate(visibleColumns, columnWidths);

    window.dd = data;

    useEffect(() => {
        registerEventListeners(ref.current);

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            removeEventListeners(ref.current);
        };
    }, []);

    return (
        <OuterWrapper ref={ref}>
            <Wrapper className="scrollbar-neutral">
                <Header
                    $size={visibleColumns.length}
                    style={{gridTemplateColumns: gtc.join(" ")}}
                >
                    <RowId/>
                    <TableGridHeader
                        columns={visibleColumns}
                        columnMap={table.columnMap}
                        tableStats={tableStats}
                        tablePreview={tablePreview}
                        updateColumnWidth={updateColumnWidth}
                    />
                </Header>

                <Body id="tg-body">
                    {data.map((row, rowIndex) => (
                        <Row
                            // eslint-disable-next-line no-underscore-dangle
                            key={`row-${row._id}`}
                            $size={visibleColumns.length}
                            style={{gridTemplateColumns: gtc.join(" ")}}
                        >
                            {/* eslint-disable-next-line no-underscore-dangle */}
                            <RowId rowId={row._id}/>
                            {visibleColumns.map((column, colIndex) => (
                                <Cell
                                    className={`cell ${getCellClass(table.columnMap[column])}`}
                                    // eslint-disable-next-line no-underscore-dangle
                                    key={`cell-${row._id}-${column}`}
                                    data-row-index={rowIndex}
                                    data-col-index={colIndex}
                                    data-max-row={data.length}
                                    data-max-col={visibleColumns.length}
                                    tabIndex="-1"
                                    stats={tableStats[column]}
                                    value={row[column]}
                                    columnName={column}
                                    dataType={table.columnMap[column].dataType}
                                    variableType={table.columnMap[column].variableType}
                                    semanticType={table.columnMap[column].semanticType}
                                    displaySettings={table.columnMap[column].displaySettings}
                                />
                            ))}
                        </Row>
                    ))}
                </Body>
            </Wrapper>

            <LoadingOverlay isLoading={loadingData}/>

            <TableGridFooter tableSize={table?.size}/>
        </OuterWrapper>
    );
}

TableGrid.propTypes = {
    table: PropTypes.shape({
        id: PropTypes.string,
        columnMap: PropTypes.object,
        columns: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            isHidden: PropTypes.bool
        })),
        size: PropTypes.shape({
            rows: PropTypes.number,
            bytes: PropTypes.number
        })
    }),
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    tableStats: PropTypes.object,
    tablePreview: PropTypes.object,
    loadingData: PropTypes.bool
};

const OuterWrapper = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: monospace;
  font-size: 12px;
`;

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  // max-height: 800px;
  overflow: scroll;
`;

const Row = styled.div`
  display: grid;
  // First col is RowId-cell
  grid-template-columns: ${({$size}) => `40px repeat(${$size}, minmax(180px, 1fr))`};
  &:hover .cell {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

Row.propTypes = {
    $size: PropTypes.number.isRequired
};

const Header = styled(Row)`
  position: sticky;
  top: 0;
`;

const Body = styled.div`
  // position: relative;
`;
