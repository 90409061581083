import React from "react";
import styled from "styled-components";
import {HeroTitle} from "src/pages/Web/components/HeroTitle/index.jsx";
import {TextBlock} from "src/pages/Web/components/TextBlock.jsx";
import {RhoDemo} from "src/pages/Web/pages/Start/components/Demo/index.jsx";
import {Terminal} from "src/primitives/Terminal/index.jsx";


export function StartIntro() {
    return (
        <div className="h-[60vh] flex">
            <div className="mx-auto max-w-page-xl px-4">
                <div className="block lg:flex pt-10 lg:pt-32">
                    <div className="w-full lg:w-2/5 mb-8">
                        <div>
                            <div className="mb-4 max-w-md md:max-w-md lg:max-w-lg">
                                <HeroTitle>
                                    <span className="monospace">rho.store</span> is a data platform for data scientists
                                </HeroTitle>
                            </div>
                            <div className="max-w-96">
                                <TextBlock size="medium" align="left">
                                    <span>
                                        Simplifying data storage in the cloud. Automated analysis of all datasets.
                                    </span>
                                </TextBlock>
                            </div>
                        </div>
                    </div>

                    <div className="w-full lg:w-3/5 max-w-112 md:max-w-144">
                        <RhoDemo/>
                    </div>
                </div>
            </div>
        </div>
    );
}


export function StartIntroOld() {
    const code = `import pandas as pd
from rho_store import RhoClient

client = RhoClient(api_key="$API_KEY")

data = pd.DataFrame(...)

# store DataFrame
table = client.store_df(data)

# visit table in browser
print(table.url)

# get table as DataFrame
df = rho_client.get_df(table.id)`;

    return (
        <>
            <div className="mx-auto max-w-xl">
                <HeroTitle>
                    <span className="monospace">rho.store</span> is a data platform for data scientists
                </HeroTitle>

                <div className="flex flex-col gap-4 justify-center my-6 lg:my-8">
                    <TextBlock size="medium" align="left">
                        <span>
                            {/* eslint-disable-next-line max-len */}
                            Are you tired of storing pickled objects in your GitHub-repository, or emailing CSV-files back and forth?
                        </span>
                    </TextBlock>
                    <TextBlock size="medium" align="left">
                        <span>
                            {/* eslint-disable-next-line max-len */}
                            <span className="monospace italic">rho</span> is the solution for you. It is a platform where you can store and manage pandas dataframes in the cloud, accessible with a simple python SDK.
                        </span>
                    </TextBlock>
                </div>
            </div>

            <div className="my-10"/>
            <TerminalWrapper>
                <Terminal language="python" code={code}/>
            </TerminalWrapper>

        </>
    );
}

const TerminalWrapper = styled.div`
  width: 640px;
  margin: 40px auto;
`;
