import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import {ListItemMemo} from "./SortableListItem";


export function Sortable({items}) {
    const [cards, setCards] = useState(items);
    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setCards((prevCards) => {
            // Create a copy of the cards array to avoid direct mutation
            const newCards = [...prevCards];

            // Remove the dragged card from its original position
            const [removedCard] = newCards.splice(dragIndex, 1);

            // Insert the card at the new position
            newCards.splice(hoverIndex, 0, removedCard);

            return newCards;
        });
    }, []);

    const handleDrop = useCallback((item) => {
        console.log("Item dropped: ", item);
    }, []);

    return (
        <div>
            {cards.map((card, index) => (
                <ListItemMemo
                    key={card.id}
                    index={index}
                    id={card.id}
                    text={card.text}
                    moveCard={moveCard}
                    onDrop={handleDrop}
                />
            ))}
        </div>
    );
}

Sortable.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.node,
        render: PropTypes.func
    }))
};

Sortable.defaultProps = {
    items: [{
        id: 1,
        text: "Write a cool JS library"
    },
    {
        id: 2,
        text: "Make it generic enough"
    },
    {
        id: 3,
        text: "Write README"
    },
    {
        id: 4,
        text: "Create some examples"
    },
    {
        id: 5,
        text: "Spam in Twitter and IRC to promote it (note that this element is taller than the others)"
    },
    {
        id: 6,
        text: "???"
    },
    {
        id: 7,
        text: "PROFIT"
    }]
};
