import React from "react";
import {useClose} from "@headlessui/react";
import {TableVersion} from "src/types/tableVersion";
import {TableVersionListItem} from "./TableVersionListItem";


interface TableVersionListProps {
    versions: TableVersion[];
    selectedVersion: number;
    onSelect: (version: number, callback: () => void) => void;
}


export function TableVersionList({versions, selectedVersion, onSelect}: TableVersionListProps) {
    const close = useClose();

    return (
        <div className="w-72 flex flex-col gap-2 text-xs">
            <div className="flex items-center py-1 px-2 border-b border-black/25">
                <div className="basis-16 grow-0 shrink-0">
                    Version
                </div>
                <div className="grow">
                    Created
                </div>
                <div>
                    Rows
                </div>
            </div>
            {versions.map((version) => (
                <TableVersionListItem
                    key={version.id}
                    version={version}
                    isSelected={version.version === selectedVersion}
                    onSelect={onSelect.bind(null, version.version, close)}
                />
            ))}

        </div>
    );
}
