import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {safeArrayLength} from "src/utils/misc.js";

const GET_VALUES_QUERY = gql`
    query GetValues($id: String!, $columns: [String!]!, $version: Int) {
        table (tableId: $id) {
            id
            values(columns: $columns, version: $version)
        }
    }
`;

export function useGetValues(tableId, columns, version) {
    const options = {variables: {id: tableId, columns, version}, skip: safeArrayLength(columns) < 1};
    const {data, loading, error, refetch} = useQuery(GET_VALUES_QUERY, options);

    const values = useMemo(() => {
        if (!data?.table?.values) {
            return {};
        }
        const tv = data.table.values;
        window.tv = tv;
        return tv;
    }, [data]);

    return {
        data: values, loading, error, refetch
    };
}
