import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

export const GET_DUPLICATED_ROWS_QUERY = gql`
    query GetDuplicatedRows($id: String!, $version: Int) {
        table (tableId: $id) {
            id
            duplicatedRows (version: $version)
        }
    }
`;

export function useGetDuplicatedRows(tableId, version) {
    const options = {variables: {id: tableId, version}};
    const {data, loading, error, refetch} = useQuery(GET_DUPLICATED_ROWS_QUERY, options);

    const rows = useMemo(() => {
        return data?.table?.duplicatedRows || [];
    }, [data]);

    return {
        data: rows, loading, error, refetch
    };
}
