import {max, min} from "mathjs";
import {
    ORANGE_GREEN
// eslint-disable-next-line max-len
} from "src/components/TableGrid/Header/ColumnHeaderSecondary/components/ColumnMenu/components/ColumnDisplaySettings/components/DisplayHeatmap/color_schemes.js";
import {isNumber} from "src/utils/validators.js";
import {interpolateColor} from "./heatmap_utils.js";


export class HeatmapColor {
    /**
     * Inspiration:
     * https://coolors.co/84cc16-d9f99d-ffffff-fed7aa-f97316
     * @param stats
     * @param colors
     */
    constructor(stats, colors = ORANGE_GREEN) {
        this.colors = Array.isArray(colors) && colors ? colors.slice() : ORANGE_GREEN.slice();
        this.opacity = 0.9;

        // this.colors.reverse();
        this.thresholdValues = [stats.p5, stats.p25, stats.p50, stats.p75, stats.p95];

        /*
        Distribution strategy:
        const {avg, sd} = stats;
        this.thresholdValues = [
            avg - 2 * stdDev,
            avg - 1 * stdDev,
            avg,
            avg + 1 * stdDev,
            avg + 2 * stdDev
        ];
         */
    }

    getColorBin = (value) => {
        if (!value) {
            return null;
        }

        const {colors} = this;

        for (let i = 0; i < this.thresholdValues.length; i++) {
            if (value < this.thresholdValues[i]) {
                return colors[i];
            }
        }
        return colors[colors.length - 1];
    };

    getColor = (value) => {
        if (!value) {
            return null;
        }

        const {colors, thresholdValues, opacity} = this;

        const firstColor = colors[0];
        const lastColor = colors[colors.length - 1];
        const minValue = min(thresholdValues);
        const maxValue = max(thresholdValues);

        const bucket = this.getBucket(value);

        const result = {};
        if (value <= minValue) {
            result.lowerColor = firstColor;
            result.upperColor = firstColor;
            result.lowerValue = minValue;
            result.upperValue = minValue;
        } else if (value >= maxValue) {
            result.lowerColor = lastColor;
            result.upperColor = lastColor;
            result.lowerValue = maxValue;
            result.upperValue = maxValue;
        } else {
            result.lowerColor = colors[bucket - 1];
            result.upperColor = colors[bucket];
            result.lowerValue = thresholdValues[bucket - 1];
            result.upperValue = thresholdValues[bucket];
        }

        // Interpolate between the two colors based on the value's position in the gradient
        const valuePosition = (value - result.lowerValue) / (result.upperValue - result.lowerValue);
        const adjustedPosition = isNumber(valuePosition) ? Math.max(Math.min(valuePosition, 1), 0) : 0;

        return interpolateColor(result.lowerColor, result.upperColor, adjustedPosition, opacity);
    };

    getBucket = (value) => {
        for (let i = 0; i < this.thresholdValues.length - 1; i++) {
            if (value <= this.thresholdValues[i]) {
                return i;
            }
        }
        return this.thresholdValues.length - 1;
    };
}
