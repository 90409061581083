import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {deepEqual} from "src/utils/misc.js";


export class PersistentStorage {
    constructor(key, defaultValue) {
        this.key = key;
        if (defaultValue !== undefined && !this.get()) {
            this.store(defaultValue);
        }
    }

    storeInt = (value) => {
        if (!Number.isNaN(value)) {
            this.store(value);
        }
    };

    getInt = (fallbackValue) => {
        try {
            const value = parseInt(this.get(), 10);

            return Number.isNaN(value) ? fallbackValue : value;
        } catch (e) {
            return fallbackValue;
        }
    };

    store = (value) => {
        window.localStorage.setItem(this.key, value);
    };

    get = () => {
        return window.localStorage.getItem(this.key);
    };

    storeJSON = (value) => {
        window.localStorage.setItem(this.key, JSON.stringify(value));
    };

    getJSON = () => {
        const value = this.get();

        try {
            return JSON.parse(value);
        } catch (e) {
            return null;
        }
    };
}

export function usePersistentStorage(key, defaultValue) {
    const storage = useMemo(() => {
        return new PersistentStorage(key, defaultValue);
    }, [key, defaultValue]);

    const [state, setState] = useState(storage.get());

    const setValue = useCallback((v) => {
        setState(v);
    }, [setState]);

    const toggle = useCallback(() => {
        setState((prevState) => !prevState);
    }, []);

    useEffect(() => {
        if (storage.get() !== state) {
            storage.store(state);
        }
    }, [state, storage]);

    return {
        value: state,
        setValue,
        toggle
    };
}


export function usePersistentJSON(key, defaultValue) {
    const timer = useRef();
    const storage = useRef();
    storage.current = new PersistentStorage(key);

    const [state, setState] = useState(storage.current.getJSON() || defaultValue);

    const setValue = useCallback((func) => {
        setState(func);
    }, [setState]);

    const value = useMemo(() => {
        return state || defaultValue;
    }, [state, defaultValue]);

    useEffect(() => {
        if (!state) {
            return;
        }

        const storedData = storage.current.getJSON();
        if (!storedData || !deepEqual(state, storedData)) {
            if (timer.current) {
                window.clearTimeout(timer.current);
            }
            timer.current = window.setTimeout(() => {
                storage.current.storeJSON(state);
            }, 250);
        }
    }, [state]);

    return {value, setValue};
}
