import React, {JSX, ReactNode, useRef} from "react";
import {useKeyPress} from "src/utils/useKeyPress";
import {useClickOutside} from "src/utils/useClickOutside";
import {SentencesAsParagraphs} from "src/pages/Web/components/SentencesAsParagraphs";

interface FeatureDescriptionProps {
    title: string;
    description: ReactNode;
    close?: () => void;
}


export function FeatureDescription({
    title,
    description,
    close
}: FeatureDescriptionProps): JSX.Element {
    const ref = useRef(null);
    useKeyPress(close, ["Escape"]);
    useClickOutside(ref, close);

    return (
        <div
            ref={ref}
            className="w-full h-full p-6 flex flex-col relative max-sm:h-auto max-sm:pb-12"
        >
            <div className="absolute top-6 right-8">
                <button
                    type="button"
                    onClick={close}
                    // eslint-disable-next-line max-len
                    className="h-8 w-12 text-sm flex items-center justify-center rounded-md border border-white/80 hover:bg-white/10 transition-all"
                >
                    x
                </button>
            </div>
            <div className="flex justify-center my-6 max-sm:mt-12 max-sm:mb-2">
                <div className="text-white/90 text-2xl font-bold">
                    {title}
                </div>
            </div>

            <div className="w-full flex-grow">
                {typeof description === "string" ? (
                    <div className="max-w-112 mx-auto">
                        <SentencesAsParagraphs content={description}/>
                    </div>
                ) : description}
            </div>
        </div>
    );
}
