import React, {JSX, useCallback} from "react";
import styled from "styled-components";
import {FeatureContent} from "src/pages/Web/pages/Start/components/FeaturesOverview/features";
import {FeatureBox} from "./FeatureBox";
import {FeatureDescription} from "./FeatureDescription";
import {Features, featuresContent} from "./content";


interface FeatureOverlayProps {
    featureKey: string;
    close: () => void;
    isActive: boolean;
}


function FeatureOverlay({featureKey, isActive, close}: FeatureOverlayProps) {
    return (
        <OverlayWrapper
            className={`
            absolute transition-all duration-200 ease-in-out inset-[1px]
            outline outline-1 outline-black/50
            ${isActive ? "scale-1" : "inert scale-0"}
            max-sm:fixed max-sm:z-50 max-sm:overflow-y-auto
            `.trim()}
        >
            <FeatureDescription
                close={isActive ? close : undefined}
                // @ts-ignore
                title={featuresContent[featureKey]?.title}
                description={<FeatureContent featureKey={featureKey}/>}
            />
        </OverlayWrapper>

    );
}

const firstRow = [Features.PYTHON_SDK, Features.AUTOMATED_ANALYSIS];
const secondRow = [Features.VERSIONING, Features.COLLABORATION];
const allFeatures = [...firstRow, ...secondRow];


export function FeaturesOverview(): JSX.Element {
    const [selectedFeature, setSelectedFeature] = React.useState<string | null>(null);
    const closeSelectedFeature = useCallback(() => {
        setSelectedFeature(null);
    }, [setSelectedFeature]);

    const boxWidth = 960;
    const boxHeight = 640;

    return (
        <div className="w-full relative">
            <Divider className="max-sm:hidden" $size={boxWidth} style={{top: -1}}/>
            <Divider className="max-sm:hidden" $size={boxWidth} style={{top: boxHeight / 2}}/>
            <Divider className="max-sm:hidden" $size={boxWidth} style={{bottom: -1}}/>

            <div
                className={`relative overflow-hidden
                max-w-full mx-auto h-[640px] flex flex-col
                max-sm:px-4 max-sm:h-auto
                `.trim()}
                style={{width: boxWidth}}
            >
                {allFeatures.map((featureKey) => (
                    <FeatureOverlay
                        key={featureKey}
                        featureKey={featureKey}
                        close={closeSelectedFeature}
                        isActive={selectedFeature === featureKey}
                    />
                ))}

                <div className="basis-1/2 overflow-hidden flex max-sm:block">
                    {firstRow.map((key) => (
                        <FeatureBox
                            key={key}
                            // @ts-ignore
                            title={featuresContent[key].title}
                            // @ts-ignore
                            description={featuresContent[key].description}
                            onClick={setSelectedFeature.bind(null, key)}
                        />
                    ))}
                </div>

                <div className="basis-1/2 overflow-hidden flex max-sm:block">
                    {secondRow.map((key) => (
                        <FeatureBox
                            key={key}
                            // @ts-ignore
                            title={featuresContent[key].title}
                            // @ts-ignore
                            description={featuresContent[key].description}
                            onClick={setSelectedFeature.bind(null, key)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

const Divider = styled.div<{$size: number}>`
  position: absolute;
  height: 1px;
  width: 100%;
  background: linear-gradient(to right,
  rgba(255, 255, 255, 0.08),
  rgba(255, 255, 255, 0.4) calc(50% - ${({$size}) => $size / 2}px),
  rgba(255, 255, 255, 0.5) calc(50%),
  rgba(255, 255, 255, 0.4) calc(50% + ${({$size}) => $size / 2}px),
  rgba(255, 255, 255, 0.08)
  );
`;

const OverlayWrapper = styled.div`
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(7px);
`;
