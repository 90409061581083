export const overrideSystemHandling = (event) => {
    if (!event) {
        return;
    }

    if (event.preventDefault) {
        event.preventDefault();
    }
    if (event.stopPropagation) {
        event.stopPropagation();
    }
};
