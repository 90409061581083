import React from "react";
import PropTypes from "prop-types";
import {useRefreshWorkspace} from "src/api/workspaces/useRefreshWorkspace.js";
import {LargeCard} from "src/pages/Home/pages/TablePage/components/LargeCard.jsx";
import {humanFileSize} from "src/utils/formatting.js";
import {Spinner} from "src/primitives/spinner.jsx";
import {Button} from "src/primitives/button.jsx";
import {useGetWorkspaceUsage} from "src/api/workspaces/useGetWorkspaceUsage.js";


export function WorkspaceUsage({workspaceId}) {
    const {workspace, loading} = useGetWorkspaceUsage(workspaceId);

    const [refresh, {loading: refreshing}] = useRefreshWorkspace(workspaceId);

    return (
        <LargeCard
            title="Usage"
            subtitle={loading && <Spinner theme="black" size="sm"/>}
        >
            {workspace && (
                <div className="mb-6">
                    <ul className="ml-4">
                        <li className="list-disc">
                            Total tables: <span className="font-bold">{workspace?.usage?.totalTables}</span>
                        </li>
                        <li className="list-disc">
                            {/* eslint-disable-next-line max-len */}
                            Total size: <span className="font-bold">{humanFileSize(workspace?.usage?.totalBytes)}</span>
                        </li>
                    </ul>
                </div>
            )}

            <div>
                <Button
                    theme="secondary"
                    onClick={() => refresh()}
                    isLoading={refreshing}
                >
                    Refresh
                </Button>
            </div>
        </LargeCard>
    );
}


WorkspaceUsage.propTypes = {
    workspaceId: PropTypes.string.isRequired
};
