import React from "react";
import {ChakraProvider} from "@chakra-ui/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";
import {ApolloProvider} from "@apollo/client";
import {RouterProvider} from "react-router-dom";
import {ClerkProvider} from "@clerk/clerk-react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {HelmetProvider} from "react-helmet-async";
import {routes} from "src/routes.jsx";
import {config} from "src/config.js";
import {usePingServer} from "src/api/usePing.js";
import {EventTracking} from "src/events.js";
import {getClient} from "./client.js";
import "./index.css";
import "./utils.css";
import "./animations.css";


const emotionCache = createCache({
    // https://stackoverflow.com/questions/73073413/how-to-override-chakra-ui-or-emotion-css-in-js-styling-with-normal-css-class-n
    key: "emotion-css-cache",
    prepend: true // ensures styles are prepended to the <head>, instead of appended
});

export function App() {
    const eventTracking = new EventTracking();
    if (eventTracking.engine) {
        console.log("Event tracking enabled");
    }

    const {serverReady} = usePingServer();

    if (serverReady) {
        console.log("Server is ready");
    }

    return (
        <HelmetProvider>
            <DndProvider backend={HTML5Backend}>
                <ApolloProvider client={getClient()}>
                    <CacheProvider value={emotionCache}>
                        <ChakraProvider>
                            <ClerkProvider publishableKey={config.CLERK_PUBLIC_KEY}>
                                <RouterProvider router={routes}/>
                            </ClerkProvider>
                        </ChakraProvider>
                    </CacheProvider>
                </ApolloProvider>
            </DndProvider>
        </HelmetProvider>
    );
}
