/* eslint-disable max-len */
import React, {JSX} from "react";

interface ContentItem {
    key: string;
    title: string;
    content: JSX.Element;
}

export const useCaseContent: ContentItem[] = [{
    key: "notebook-complement",
    title: "A perfect complement to your jupyter notebook",
    content: (
        <div className="max-w-160 flex flex-col gap-4">
            <p>
                Jupyter Notebooks are great for exploring data, but storing intermediate results can be a headache. Data scientists often face this challenge: you’ve run a complex SQL query to fetch a large dataset from your warehouse, transformed it into a clean dataframe, and now need a place to store it.

            </p>
            <p>
                Saving it back to the warehouse feels like overkill, especially when you know this data is just a stepping stone in your workflow.
            </p>
            <p>
                Rho is purpose-built for these situations. It provides an efficient, simple way to save and manage intermediate datasets directly from your notebook.
            </p>
        </div>
    )
}, {
    key: "csv-file",
    title: "Starting from a CSV File",
    content: (
        <div className="max-w-160 flex flex-col gap-4">
            <p>
                CSV files are a staple for quick data analysis, but they often bring their own set of challenges. You’ve got a CSV file from a colleague or an external source—what’s next? Instead of manually inspecting it, writing code to parse it, or managing local copies, Rho streamlines the process.
            </p>
            <p>
                Upload your CSV file through Rho’s web app, and your data is instantly profiled. See column types, summary statistics, and potential issues directly in your browser.
            </p>
            <p>
                From there, it’s easy to fetch the data into your Python environment. Stop juggling with ad hoc scripts or worrying about version control — Rho keeps everything organized and accessible.
            </p>
        </div>
    )
}, {
    key: "prototype-database",
    title: "A database for prototyping",
    content: (
        <div className="max-w-160 flex flex-col gap-4">
            <p>
                Prototyping often involves creating quick solutions, and traditional databases can be a barrier. They require setup, maintenance, and schema planning—time-consuming steps when you just need to test an idea.
            </p>
            <p>
                Rho eliminates these hurdles. Need to store experiment results, share a dataset with teammates, or feed data into an app? Rho’s flexible structure lets you store and retrieve your data without any setup.
            </p>
            <p>
                Rho is a practical alternative to managing local files or setting up a full database—giving you just enough structure to keep your project moving forward.
            </p>
        </div>
    )
}];
