import React from "react";
import {SentencesAsParagraphs} from "src/pages/Web/components/SentencesAsParagraphs";


interface FeatureBoxProps {
    title: string;
    description: string;
    onClick?: () => void;
}

export const FeatureBox = ({
    title,
    description,
    onClick
}: FeatureBoxProps) => {
    return (
        <button
            type="button"
            className={`
             basis-1/2 bg-white-2 hover:bg-white/5 
             border-l border-white/40 last:border-r outline outline-1 outline-white/5 hover:outline-white/40
             text-white/90 hover:text-white/95
             transition-all
             max-sm:w-full max-sm:h-auto max-sm:border max-sm:my-2
            `.trim()}
            onClick={onClick}
        >
            <div className="h-full pt-20 pb-12 px-8 flex flex-col gap-4 justify-between text-left max-sm:py-8">
                <div className="flex flex-col gap-2">
                    <div className="text-lg font-bold">
                        {title}
                    </div>
                    <div className="text">
                        <SentencesAsParagraphs content={description}/>
                    </div>
                </div>
                <div>
                    <span
                        className={`
                        text-xs text-orange-400/70 hover:text-orange-400/80 
                        border border-orange-500/60 hover:border-orange-400/80 
                        rounded-md px-3 py-1.5 
                        cursor-pointer
                        transition-all`}
                    >
                        Learn more
                    </span>
                </div>
            </div>
        </button>
    );
};
