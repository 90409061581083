import React from "react";
import {SentencesAsParagraphs} from "src/pages/Web/components/SentencesAsParagraphs.js";
import {QuestionAnswerBlock} from "./QuestionAnswerBlock";
import {qaItems} from "./content";


export function RhoDescription() {
    return (
        <div className="flex flex-col">
            {qaItems.map((item) => (
                <QuestionAnswerBlock
                    key={item.id}
                    question={(
                        <span className={item.titleColor}>
                            {item.question}
                        </span>
                    )}
                    answer={(
                        <div className="text-white/90">
                            <SentencesAsParagraphs content={item.answer}/>
                        </div>
                    )}
                />
            ))}
        </div>
    );
}
