import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
    DataTypes,
    firstValue,
    isColumnDate,
    isColumnNumeric,
    isColumnText,
    VariableTypes
} from "src/utils/tableData.js";
import {CategoryDistribution} from "src/charts/CategoryDistribution/index.jsx";
import {BooleanDistribution} from "src/charts/CategoryDistribution/BooleanDistribution.jsx";
import {getMaxValue, getMinValue, HistogramRaw} from "src/charts/HistogramRaw.jsx";
import {HistogramDate} from "src/charts/HistogramDate.jsx";
import {abbreviateNumber, formatDate} from "src/utils/formatting.js";


export function RenderIllustration({columnInfo, stats, values}) {
    const useRealMinMaxValues = true;
    const height = 100;

    const {
        variableType
    } = columnInfo;
    const isNumeric = isColumnNumeric(columnInfo);
    const isText = isColumnText(columnInfo);
    const isDate = isColumnDate(columnInfo);

    if (!stats) {
        // TODO: Loading state?
        return null;
    }

    if (isNumeric && variableType === VariableTypes.CONTINUOUS) {
        const minValue = useRealMinMaxValues ? stats.min : getMinValue([values]);
        const maxValue = useRealMinMaxValues ? stats.max : getMaxValue([values]);

        return (
            <>
                <HistogramRaw
                    datasets={[values]}
                    height={height}
                    showY={false}
                    showTooltip={false}
                    showXLabel={false}
                />
                <MinMax>
                    <span>{abbreviateNumber(minValue)}</span>
                    <span>{abbreviateNumber(maxValue)}</span>
                </MinMax>
            </>
        );
    }

    if (variableType === VariableTypes.CATEGORICAL) {
        return (
            <Centered className="px-1">
                <CategoryDistribution
                    values={values}
                    maxItems={3}
                    height={height}
                />
            </Centered>
        );
    }

    if (isDate) {
        const lowerValues = values.map(({lower}) => new Date(lower).getTime());
        const minValue = Math.min(...lowerValues);
        const upperValues = values.map(({upper}) => new Date(upper).getTime());
        const maxValue = Math.min(...upperValues);

        return (
            <>
                <HistogramDate
                    values={values}
                    height={height}
                    showY={false}
                    showTooltip={false}
                    showXLabel={false}
                />
                <MinMax>
                    <span>{formatDate(minValue)}</span>
                    <span>{formatDate(maxValue)}</span>
                </MinMax>
            </>
        );
    }

    if (variableType === VariableTypes.CONSTANT) {
        return (
            <Centered>
                <Description>
                    All rows are
                </Description>
                <Value>
                    {firstValue(values).name}
                </Value>
            </Centered>
        );
    }

    if (variableType === VariableTypes.IDENTIFIER) {
        return (
            <Centered>
                <Description>
                    <strong>{stats.values}</strong> unique values
                </Description>
            </Centered>
        );
    }

    if (isText) {
        // Just random rext
        return (
            <Centered>
                <Description>
                    <strong>{stats.distinct}</strong> different values
                </Description>
            </Centered>
        );
    }

    if (columnInfo.dataType === DataTypes.BOOLEAN) {
        return (
            <Centered>
                <BooleanDistribution
                    values={values}
                    height={height}
                />
            </Centered>
        );
    }

    return (
        <Centered>
            {columnInfo.dataType}
        </Centered>
    );
}


RenderIllustration.propTypes = {
    stats: PropTypes.shape({
        values: PropTypes.number,
        distinct: PropTypes.number,
        min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        max: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    columnInfo: PropTypes.shape({
        dataType: PropTypes.string,
        variableType: PropTypes.string
    }),
    values: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string, PropTypes.number, PropTypes.object
    ]))
};

const MinMax = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: monospace;
  color: #6e7079;
  padding: 0 4px;
  font-size: 11px;
`;

const Centered = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const Description = styled.div`
  font-weight: normal;
`;

const Value = styled.div`
  font-weight: bold;
`;
