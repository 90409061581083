import {RefObject, useEffect} from "react";
import {overrideSystemHandling} from "src/utils/system";

export const useClickOutside = (
    ref: RefObject<HTMLElement | null>,
    handleOnClickOutside?: (event: MouseEvent | TouchEvent) => void
) => {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (!ref.current || ref.current.contains(event.target as Node)) {
                return;
            }

            if (handleOnClickOutside) {
                overrideSystemHandling(event);
                handleOnClickOutside(event);
            }
        };

        document.addEventListener("mousedown", listener);
        // document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            // document.removeEventListener("touchstart", listener);
        };
    }, [ref, handleOnClickOutside]);
};
