import React, {ReactNode} from "react";


interface QuestionAnswerBlockProps {
    question: ReactNode;
    answer: ReactNode;
}

export function QuestionAnswerBlock({
    question,
    answer
}: QuestionAnswerBlockProps) {
    return (
        <div
            className="w-full bg-white-2 border-t border-b border-white/5"
        >
            <div className="w-page-lg mx-auto max-w-full">
                <div className="flex min-h-48 px-6 max-sm:block">
                    <div
                        className={`
                        m-py-lg
                        basis-1/2 flex justify-end text-right
                        border-r border-white/5 pr-10
                        max-sm:border-r-0 max-sm:border-b max-sm:justify-start max-sm:text-left
                        `}
                    >
                        <div
                            className="text-lime-400/45 text-4xl max-w-96 max-sm:text-2xl max-sm:pl-8"
                        >
                            {question}
                        </div>
                    </div>
                    <div
                        className="basis-1/2 flex m-py-lg justify-start pl-10 max-sm:pl-8"
                    >
                        {answer}
                    </div>
                </div>
            </div>
        </div>
    );
}
