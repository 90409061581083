import {useSearchParams} from "react-router-dom";
import {useCallback} from "react";

export function getUrlParams() {
    const params = {};
    const paramsString = window.document.URL.split("?")[1];
    if (!paramsString) {
        return params;
    }

    paramsString.split("&")
        .forEach((el) => {
            const [key, value] = el.split("=");
            params[key.trim()] = decodeURIComponent(value.trim());
        });

    return params;
}

export function useRemoveSearchParam() {
    const [searchParams, setSearchParams] = useSearchParams();

    return useCallback((key) => {
        searchParams.delete(key);
        setSearchParams(searchParams);
    }, [setSearchParams, searchParams]);
}
