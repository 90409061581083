import React, {JSX} from "react";
import {cva} from "cva";
import styled from "styled-components";
import {StepIndexNumber} from "./StepIndexNumber";


const stepIndexClasses = cva({
    base: "overflow-hidden p-4 rounded-xl cursor-pointer border transition-all duration-300",
    variants: {
        state: {
            normal: "border-black/5 hover:bg-white-2 hover:border-white/5",
            active: "border-white/5 bg-white-1",
            alt: "bg-gradient-to-r from-white/5"
        }
    }
});


interface StepIndexProps {
    index: number;
    selected: boolean;
    title: string;
    description: string;
}

export function StepIndex({
    index,
    selected,
    title,
    description
}: StepIndexProps): JSX.Element {
    return (
        <div className={stepIndexClasses({state: selected ? "active" : "normal"})}>
            <div
                className="flex flex-col gap-4 transition-all duration-300"
                style={{height: selected ? 210 : 40}}
            >
                <div className="flex flex-row items-center gap-4">
                    <div className="basis-10 no-flex">
                        <StepIndexNumber index={index} isActive={selected}/>
                    </div>
                    <div>
                        <span className="text-lg font-bold">
                            {title}
                        </span>
                    </div>
                </div>

                <div
                    className="flex flex-grow flex-row min-h-[140px] gap-4"
                    style={{opacity: selected ? 1 : 0}}
                >
                    <div
                        className="basis-10 no-flex h-full flex justify-center py-1"
                    >
                        <Line className="h-full w-[1px] rounded-md"/>
                    </div>
                    <div className="pr-2">
                        <div className="text-white/80">
                            {description}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const Line = styled.div`
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.75), rgba(163, 230, 53, 0.75)); // lime-400
`;
