import React from "react";
import {HeroTitle} from "src/pages/Web/components/HeroTitle/index.jsx";
import {TextBlock} from "src/pages/Web/components/TextBlock.jsx";
import {isProduction} from "src/config.js";
import {getDemoUrl} from "src/pages/Web/pages/Start/utils.js";
import {LiveDemo} from "./components/LiveDemo";


export function StartIntoV2() {
    return (
        <div
            className={`
            min-h-[80vh] flex flex-col justify-between mx-4
            max-sm:h-auto max-sm:min-h-0
            `}
        >
            <div>
                <div className="mb-4 text-center">
                    <HeroTitle>
                        The lightweight data warehouse <br/>
                        for data scientists.
                    </HeroTitle>
                </div>

                <div className="flex align-center justify-center">
                    <TextBlock size="medium" align="center">
                        <span>
                            The easiest way to store and manage your data.<br/>
                            Automated analysis of all datasets.
                        </span>
                    </TextBlock>
                </div>
            </div>

            <div className="mt-12 max-sm:mt-0">
                <div className="flex flex-col">
                    <div className="max-sm:hidden">
                        <LiveDemo demoUrl={getDemoUrl()}/>
                    </div>

                    <div className="w-64 mx-auto my-20 max-sm:my-8 flex justify-center">
                        <a
                            href={getDemoUrl(isProduction())}
                            target="_blank"
                            rel="noreferrer"
                            className={`px-4 py-2 rounded-md 
                            border border-white/25 hover:border-white/40
                            hover:bg-white/5
                            transition-all
                            `.trim()}
                        >
                            See live demo
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
