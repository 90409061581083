import React from "react";
import {Navigate} from "react-router-dom";
import {StartPage} from "src/pages/Web/pages/Start";
import {PrivacyPage} from "src/pages/Web/pages/Privacy";
import {TermsPage} from "src/pages/Web/pages/Terms";
import {SubProcessorsPage} from "src/pages/Web/pages/SubProcessors";
import {LINKS} from "src/links.js";
import {CsvToApiPage, FitDistributionPage, SavePandasDataframePage} from "src/pages/Web/pages/LandingPages";


export const webRoutes = [{
    path: "",
    element: <StartPage/>
}, {
    path: "privacy",
    element: <PrivacyPage/>
}, {
    path: "terms",
    element: <TermsPage/>
}, {
    path: "sub-processors",
    element: <SubProcessorsPage/>
}, {
    path: "save-pandas-dataframe",
    element: <SavePandasDataframePage/>
}, {
    path: "fit-distributions",
    element: <FitDistributionPage/>
}, {
    path: "csv-to-api",
    element: <CsvToApiPage/>
}, {
    path: "*",
    element: (
        <Navigate to={LINKS.WEB}/>
    )
}];
