import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
    useLatestOptions,
    useLoadWithOptions,
    useTableDataResult
} from "src/pages/Home/pages/TablePage/pages/TableGridPage/table_data_context.jsx";
import {formatNumber} from "src/utils/formatting.js";
import {DEFAULT_PAGE_SIZE} from "src/components/TableGrid/constants.js";
import {ResultInfo} from "./ResultInfo/index.jsx";
import {TablePagination} from "./Pagination/index.jsx";


export function TableGridFooter({tableSize}) {
    const [tempTotalRows, setTempTotalRows] = useState(null);
    const {handlePagination} = useLoadWithOptions();
    const {page: currentPage, limit} = useLatestOptions();
    const {result} = useTableDataResult();
    // fallback to default page size
    const pageSize = limit || DEFAULT_PAGE_SIZE;

    useEffect(() => {
        if (!result || result?.totalRows === null) {
            return;
        }
        if (result.totalRows !== tempTotalRows) {
            setTempTotalRows(result.totalRows);
        }
    }, [result, setTempTotalRows, tempTotalRows]);

    return (
        <Wrapper className="border-t border-neutral-300 bg-neutral-200/80 text-neutral-700">
            <div className="w-64">
                <ResultInfo tableSize={tableSize}/>
            </div>

            <TablePagination
                totalRows={tempTotalRows}
                pageSize={pageSize}
                currentPage={currentPage}
                selectPage={handlePagination}
            />

            <div className="w-64 text-right">
                Total: {formatNumber(tempTotalRows, 0)} rows
            </div>
        </Wrapper>
    );
}

TableGridFooter.propTypes = {
    tableSize: PropTypes.shape({
        rows: PropTypes.number,
        bytes: PropTypes.number
    })
};

const Wrapper = styled.div`
  flex: 0 0 46px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
