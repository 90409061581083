import React, {JSX} from "react";
import {GuidedSteps} from "src/components/GuidedSteps";
import {CreateExample, ExploreExample, RetrieveExample} from "./examples";


const gettingStartedSteps = [{
    title: "Create",
    description: `
    Get started by creating your first dataset. You can either upload a CSV file from the web app, 
    or use the Python SDK to store a dataframe directly.
    `,
    content: (
        <CreateExample/>
    )
}, {
    title: "Explore",
    description: `
    The uploaded data is automatically analyzed - you can see outliers, distributions, correlations, etc.
    You can also use the chat with built in LLM-model to ask questions about the data.
    `,
    content: (
        <ExploreExample/>
    )
}, {
    title: "Retrieve",
    description: `
    Use the Python SDK to fetch the data and build something! 
    `,
    content: (
        <RetrieveExample/>
    )
}];


export function GettingStartedSteps(): JSX.Element {
    return (
        <div className="w-page-lg mx-auto my-10 max-w-full max-sm:px-4">
            <GuidedSteps items={gettingStartedSteps}/>
        </div>
    );
}
