import React, {useCallback} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {RenderIllustration} from "src/components/TableGrid/Header/ColumnIllustration/RenderIllustration.jsx";
import {useColumnDetailsState} from "src/pages/Home/pages/TablePage/components/ColumnDetailsSidebar/states.jsx";
import {ErrorComponent} from "src/components/ErrorComponent/index.jsx";
import {ColumnIllustrationError} from "src/components/TableGrid/Header/ColumnIllustration/ColumnIllustrationError.jsx";


export function ColumnIllustration({columnInfo, stats, values}) {
    const selectColumn = useColumnDetailsState((state) => state.select);
    const handleClick = useCallback(() => {
        selectColumn(columnInfo.name);
    }, [selectColumn, columnInfo]);

    return (
        <Wrapper
            onClick={handleClick}
            // eslint-disable-next-line max-len
            className="flex-1 flex flex-col bg-white text-black p-1 transition-all hover:bg-neutral-200/40 hover:shadow-lg"
        >
            <ErrorComponent
                fallback={(
                    <ColumnIllustrationError columnInfo={columnInfo} stats={stats}/>
                )}
            >
                <RenderIllustration
                    stats={stats}
                    columnInfo={columnInfo}
                    values={values}
                />
            </ErrorComponent>
        </Wrapper>
    );
}

ColumnIllustration.propTypes = {
    stats: PropTypes.shape({
        values: PropTypes.number,
        distinct: PropTypes.number
    }),
    columnInfo: PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string,
        variableType: PropTypes.string
    }),
    values: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string, PropTypes.number, PropTypes.object
    ]))
};

const Wrapper = styled.div`
  cursor: pointer;
  & * {
    // Override charts etc
    cursor: pointer !important;
  }
`;
