import {useEffect, useState} from "react";

interface MarkdownContentReturn {
    content: string;
    isLoading: boolean;
    error: Error | null;
}


export function useMarkdownContent(importPath: () => Promise<{ default: string }>): MarkdownContentReturn {
    const [content, setContent] = useState<string>("");
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        async function loadContent() {
            try {
                const markdown = await importPath();
                setContent(markdown.default);
            } catch (err) {
                setError(err instanceof Error ? err : new Error("Unknown error loading markdown"));
            }
        }

        loadContent();
    }, [importPath]);


    return {content, isLoading: content === "", error};
}
