import React, {useContext} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Skeleton, Spacer} from "@chakra-ui/react";
import {FiMenu} from "react-icons/fi";
import {SidebarContext} from "src/primitives/layout/sidebar_context.jsx";
import {Title} from "src/primitives/title.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {useUser} from "@clerk/clerk-react";
import {Avatar} from "src/primitives/Avatar.jsx";
import {Dropdown} from "src/primitives/Dropdown/index.jsx";
import {useGetLink} from "src/utils/useGetLink.js";
import {LINKS} from "src/links.js";


export const TOGGLE_SIDEBAR_BUTTON_ID = "toggle-sidebar-button";


export function getSidebarButton() {
    return window.document.getElementById(TOGGLE_SIDEBAR_BUTTON_ID);
}


export function PageHeader({title, loading}) {
    const {toggle: toggleSidebar} = useContext(SidebarContext);
    const {user} = useUser();
    const getLink = useGetLink();

    const dropdownOptions = [{
        title: "Account settings",
        link: getLink(LINKS.ACCOUNT)
    }, {
        title: "Workspace settings",
        link: getLink(LINKS.WORKSPACE_SETTINGS)
    }, {
        title: "Sign out",
        link: LINKS.SIGN_OUT
    }];

    return (
        <Wrapper className="px-2">
            <div className="w-full flex items-center gap-1">
                <IconWrapper onClick={toggleSidebar} size="small">
                    <FiMenu/>
                </IconWrapper>
                {loading ? (
                    <Skeleton isLoaded={false} className="w-64 h-5"/>
                ) : (
                    <div>
                        {typeof title === "string" ? (
                            <Title size="xs">{title}</Title>
                        ) : title}
                    </div>
                )}
                <Spacer/>

                <div className="flex item-center gap-2 pr-2">
                    <Dropdown options={dropdownOptions}>
                        <Avatar user={user}/>
                    </Dropdown>
                </div>
            </div>
        </Wrapper>
    );
}

PageHeader.propTypes = {
    title: PropTypes.node,
    loading: PropTypes.bool
};

PageHeader.defaultProps = {
    title: "Title"
};

const Wrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
`;
