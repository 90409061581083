import {gql, useQuery} from "@apollo/client";
import {useMemo} from "react";

export const GET_TABLE_VERSIONS_QUERY = gql`
    query GetTableVersions($id: String!) {
        table (tableId: $id) {
            id
            versions {
                id
                version
                totalRows
                createdAt
                lastUpdatedAt
            }
        }
    }
`;


export function useGetTableVersions(tableId) {
    const {data, loading, error, refetch} = useQuery(GET_TABLE_VERSIONS_QUERY, {variables: {id: tableId}});

    const versions = useMemo(() => {
        return data?.table?.versions || [];
    }, [data]);

    return {
        versions, loading, error, refetch
    };
}
