import React from "react";
import {Terminal} from "src/primitives/Terminal/index.jsx";


export function Versioning() {
    return (
        <div>
            <div className="flex flex-row-reverse items-center max-sm:flex-col">
                <div className="p-8 flex flex-col gap-3 max-w-96">
                    <p>
                        Rho supports keeping multiple versions of a datasets.
                    </p>
                    <p>
                        The built in versioning system allows you to track changes for each columns,
                        revert to previous versions, and maintain a complete audit trail.
                    </p>
                </div>

                <div className="basis-1/2 max-w-full overflow-x-auto max-sm:px-4">
                    <Terminal
                        hideTopBar={true}
                        language="python"
                        code={`
# %pip install rho-store
import pandas as pd
from rho_store import RhoClient

client = RhoClient(api_key="$API_KEY")

# fetch table, defaults to latest version
table = client.get_table("demo/stocks")
print(table.version)

# fetch specific version
table_v3 = client.get_table(
    "demo/stocks",
    version=3
)
print(table_v3.version)`.trim()}
                    />
                </div>

            </div>
        </div>
    );
}
