import React from "react";

export function SentencesAsParagraphs({content}: { content: string }) {
    if (!content) {
        return null;
    }

    const sentences = content.split(".").filter((sentence) => sentence.trim().length > 0);

    return (
        <div
            className="flex flex-col gap-2"
        >
            {sentences.map((sentence) => (
                <p key={sentence}>
                    {sentence}.
                </p>
            ))}
        </div>
    );
}
