import React, {JSX} from "react";
import {FiChevronRight} from "react-icons/fi";
import {cva} from "cva";
import {IconWrapper} from "src/primitives/Icon";


const chevronClasses = cva({
    base: "h-8 w-10 flex-center rounded-md border-2 hover:bg-white-2 transition-all",
    variants: {
        state: {
            normal: "border-white/60 text-white/75",
            active: "border-lime-500/60 text-lime-500/80 *:rotate-90"
        }
    }
});


const titleTextContent = cva({
    base: "text-xl font-bold hover:text-white/95 transition-all",
    variants: {
        state: {
            normal: "text-white/75",
            active: " text-white/90"
        }
    }
});


interface UseCaseProps {
    title: string;
    content: JSX.Element;
    isActive: boolean;
    setIsActive: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function UseCaseContent({
    title,
    content,
    isActive,
    setIsActive
}: UseCaseProps): JSX.Element {
    const state = isActive ? "active" : "normal";

    return (
        <div
            className="flex flex-col gap-2"
        >
            <button
                className="flex items-center gap-6 p-1"
                type="button"
                onClick={setIsActive}
            >
                <div>
                    <div
                        className={chevronClasses({state})}
                    >
                        {/* @ts-ignore */}
                        <IconWrapper icon={<FiChevronRight/>} size="small"/>
                    </div>
                </div>

                <div className="flex flex-col">
                    <div className="my-[2px] text-left">
                        <span className={titleTextContent({state})}>
                            {title}
                        </span>
                    </div>
                    <div
                        className="h-[2px] rounded-xl bg-lime-500/60 transition-all duration-500"
                        style={{width: isActive ? "calc(100% + 12px)" : 0}}
                    />
                </div>
            </button>

            <div
                className="transition-all duration-500 p-1 overflow-hidden"
                style={{minHeight: isActive ? 160 : 0, maxHeight: isActive ? "none" : 0}}
            >
                <div
                    className="flex flex-col gap-2 text-left transition-all duration-500 pl-16"
                    style={{opacity: isActive ? 1 : 0, paddingBottom: isActive ? 16 : 0}}
                >
                    <div className="text-white/90">
                        {content}
                    </div>
                </div>
            </div>
        </div>
    );
}
