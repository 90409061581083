import React from "react";
import {cva} from "cva";


const stepperIndexClasses = cva({
    base: "w-10 h-10 rounded-xl flex items-center justify-center border monospace transition-all duration-300",
    variants: {
        state: {
            normal: "border-white/40 text-white/70",
            active: "border-lime-500/60 text-lime-500/80"
        }
    }
});

export const StepIndexNumber = ({index, isActive}: { index: number, isActive: boolean }) => {
    return (
        <div
            className={stepperIndexClasses({state: isActive ? "active" : "normal"})}
        >
            <span className="text-lg">
                {index}
            </span>
        </div>
    );
};
