import React, {useState} from "react";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiMenu} from "react-icons/fi";
import {useClickOutside} from "src/utils/useClickOutside";

interface MenuItem {
    title: string;
    link: string;
}

interface HamburgerMenuProps {
    items: MenuItem[];
}


export const HamburgerMenu = ({items}: HamburgerMenuProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = React.useRef(null);
    useClickOutside(menuRef, () => setIsOpen(false));

    return (
        <div className="relative">
            {/* Hamburger Button */}
            <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className="p-2 text-white/75 hover:text-white/95 focus:outline-none"
                aria-label="Toggle menu"
            >
                {/* @ts-ignore */}
                <IconWrapper icon={<FiMenu/>} size="xs"/>
            </button>

            {/* Dropdown Menu */}
            {isOpen && (
                <div
                    ref={menuRef}
                    // eslint-disable-next-line max-len
                    className="absolute right-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                >
                    <div className="py-1">
                        {items.map((item) => (
                            <a
                                key={item.link}
                                href={item.link}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                                {item.title}
                            </a>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
