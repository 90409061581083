import React, {JSX} from "react";
import {Tab, TabGroup, TabList, TabPanel, TabPanels} from "@headlessui/react";
import {StepIndex} from "./StepIndex";

interface StepperItem {
    title: string;
    description: string;
    content: JSX.Element;
}

interface GuidedStepsProps {
    items: StepperItem[];
}

export function GuidedSteps({items}: GuidedStepsProps): JSX.Element {
    return (
        <TabGroup
            vertical={true}
            className="flex flex-row"
        >
            <TabList
                as="aside"
                className="flex flex-col gap-2 mr-16 max-sm:mr-0"
            >
                {items.map((item, index) => (
                    <Tab
                        as="a"
                        key={`tab-${item.title}`}
                        className="w-88 rm-focus max-sm:w-full"
                    >
                        {({selected}) => (
                            <StepIndex
                                index={index + 1}
                                selected={selected}
                                title={item.title}
                                description={item.description}
                            />
                        )}
                    </Tab>
                ))}
            </TabList>

            <TabPanels
                as="section"
                className="flex items-center justify-center flex-grow p-4 max-sm:hidden"
            >
                {items.map((item) => (
                    <TabPanel
                        key={`tab-panel-${item.title}`}
                        className="appear-fast"
                    >
                        {item.content}
                    </TabPanel>
                ))}
            </TabPanels>
        </TabGroup>
    );
}
