import React from "react";
import {MarkdownContent} from "src/primitives/Markdown/index.jsx";
import {PageWrapper} from "src/pages/Web/components/layouts.jsx";
import {PageMeta} from "src/pages/Web/components/PageMeta";
import {useMarkdownContent} from "src/primitives/Markdown/useMarkdownContent";

export function SavePandasDataframePage() {
    const {content, isLoading} = useMarkdownContent(() => {
        // @ts-ignore
        return import("./content.md?raw");
    });

    if (isLoading) {
        return null;
    }

    return (
        <>
            <PageMeta
                title="rho.store - saves pandas dataframe"
                description={`
                How to save pandas dataframes efficiently.
                `}
            />
            <PageWrapper>
                <MarkdownContent content={content}/>
            </PageWrapper>
        </>
    );
}
