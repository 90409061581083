import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import {Outlet, useLocation} from "react-router-dom";
import {GetStarted} from "src/pages/Web/sections/GetStarted/index.jsx";
import {WebHeader} from "./sections/Header/index.jsx";
import {WebFooter} from "./sections/Footer/index.jsx";


export function HeaderWrapper() {
    return (
        <header
            className={`
                    w-full
                    sticky top-0 z-40
                    border-b border-white/10
                    before:absolute before:w-full before:h-full before:backdrop-blur 
                    before:transition-colors before:duration-500 before:-z-[1]`.trim()}
        >
            <div className="w-page-xl max-w-full mx-auto">
                <WebHeader/>
            </div>
        </header>
    );
}

export function WebPage() {
    const ref = useRef();
    const location = useLocation();

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTo(0, 0);
        }
    }, [location]);

    return (
        <Wrapper
            ref={ref}
            className="min-h-screen w-full flex flex-col overflow-auto"
        >
            <HeaderWrapper/>

            <main>
                <Outlet/>
            </main>

            <GetStarted />

            <footer className="border-t border-white/10 pb-10">
                <div className="w-page-xl max-w-full mx-auto">
                    <WebFooter/>
                </div>
            </footer>
        </Wrapper>
    );
}


const Wrapper = styled.div`
  color: #FFFFFF;

  // Nice
  // https://cssgradient.io/
  // background: linear-gradient(142deg, rgb(28, 28, 28) 0%, rgba(37, 37, 38, 0.98) 65%, rgb(26, 35, 27) 100%);

  background: var(--bg-dark-primary);
`;

/*
const BackgroundImage = styled.div`
  position: absolute;
  background-image: url("${bg}");
  background-repeat: no-repeat;
  background-size: cover;
  height: min(max(100vh,1000px),1500px);
  transform: translate(-54%,-5%) scale(4);
  width: min(max(100vh,1000px),1300px);
`;
*/
