import React, {JSX, useCallback} from "react";
import {ColumnStats, TableColumn, TableStats} from "src/types";
import {TableVersion} from "src/types/tableVersion";
import {SimpleTable} from "src/components/SimpleTable";
import {formatNumber, formatPercent} from "src/utils/formatting";


function formatVersionStats(columns: TableColumn[], versions: TableVersion[], stats: TableStats[]): object[] {
    /**
     * Format:
     * {name: "column_name", version1: value, version2: value, ...}
     */
    const statsMapByVersion = stats.reduce((acc, item) => {
        // @ts-ignore
        return Object.assign(acc, {[item.version]: item.data});
    }, {});

    return columns.map((column) => {
        const row = {
            name: column.name,
            position: column.position,
            dataType: column.dataType
        };
        versions.forEach((version) => {
            const key = `v${version.version}`;
            // @ts-ignore
            row[key] = statsMapByVersion[version.version]?.[column.name];
        });
        return row;
    });
}


function getDisplayColumns(versions: TableVersion[]): object[] {
    const displayColumns: object[] = [];
    displayColumns.push({
        key: "position",
        header: "#",
        getStyle: () => ({width: "40px", color: "grey"})
    });

    displayColumns.push({key: "name", header: "Column"});

    versions.forEach((version) => {
        displayColumns.push({
            key: `v${version.version}`,
            header: `Values - v${version.version}`,
            render: (colStats: ColumnStats) => {
                return (
                    <div className="flex items-center gap-4">
                        <div>
                            {formatNumber(colStats.values, 0)}
                        </div>
                        <div className="text-neutral-600">
                            ({formatPercent(colStats.values / colStats.rows, 2)})
                        </div>
                    </div>
                );
            }
        });
    });

    return displayColumns;
}


interface TableVersionsTableProps {
    columns: TableColumn[];
    stats: TableStats[];
    versions: TableVersion[];
    selectColumn: (column: TableColumn) => void;
}


interface Row {
    name: string;
}


export function ColumnComparison({
    columns,
    stats,
    versions,
    selectColumn
}: TableVersionsTableProps): JSX.Element {
    const data = formatVersionStats(columns, versions, stats);
    const displayColumns = getDisplayColumns(versions);

    const handleRowClick = useCallback((row: Row) => {
        const column = columns.find((col) => col.name === row.name);
        if (column && selectColumn) {
            selectColumn(column);
        }
    }, [selectColumn, columns]);

    return (
        <div>
            <div className="max-w-page-lg">
                {/* @ts-ignore */}
                <SimpleTable
                    data={data}
                    columns={displayColumns}
                    onRowClick={handleRowClick}
                />
            </div>
        </div>
    );
}
