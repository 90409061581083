import React from "react";
import {Image} from "src/pages/Web/components/Image.jsx";
import sharingEx from "src/pages/Web/assets/screenshots/rho_sharing.png";
import {isProduction} from "src/config.js";
import {getDemoUrl} from "src/pages/Web/pages/Start/utils.js";


export function Collaboration() {
    const demoUrl = getDemoUrl(isProduction());

    return (
        <div className="mt-16 mx-8 max-sm:my-4">
            <div className="flex gap-6 max-sm:flex-col">
                <div className="basis-1/2 flex-center">
                    <div className="flex flex-col gap-2 max-w-88">
                        <p>
                            Collaborate with friends and colleagues with native sharing features.
                            No more emails with outdated CSV files.
                        </p>
                        <p>
                            You can also create a public link to share your data with the world.
                        </p>
                        <p>
                            <a
                                href={demoUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-400/90 hover:underline"
                            >
                                See example of public link
                            </a>
                        </p>
                    </div>
                </div>

                <div className="basis-1/2">
                    <div className="h-64">
                        <Image
                            source={sharingEx}
                            opacity={0.9}
                            alignX="center"
                            alignY="center"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
