import React, {useCallback, useEffect} from "react";
import PropTypes from "prop-types";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {useSelectedTableVersion} from "src/utils/urlVariables/tableVersion.js";
import {useGetTableVersions} from "src/api/tables/useGetTableVersions.js";
import {Overlay} from "src/primitives/Overlay/index.jsx";
import {tryParseInt} from "src/utils/formatting.js";
import {SelectTableVersionButton} from "./SelectTableVersionButton.tsx";
import {TableVersionList} from "./TableVersionList";


export function SelectTableVersion({tableId}) {
    const {table} = useGetTable(tableId);
    const {versions} = useGetTableVersions(tableId);
    const [tableVersion, setTableVersion] = useSelectedTableVersion();
    const latestVersion = table?.latestVersion;

    const handleSelect = useCallback((version, callback) => {
        if (callback) {
            callback();
        }
        setTableVersion(version);
    }, [setTableVersion]);

    useEffect(() => {
        if (latestVersion && !tableVersion) {
            console.log("Setting table version to latest");
            setTableVersion(latestVersion);
        } else if (tableVersion && latestVersion && tableVersion > latestVersion) {
            console.warn("Table version is higher than latest version, resetting to latest");
            setTableVersion(latestVersion);
        }
    }, [tableVersion, setTableVersion, latestVersion]);

    if (versions.length <= 1) {
        return null;
    }

    return (
        <Overlay
            content={(
                <TableVersionList
                    versions={versions}
                    selectedVersion={tryParseInt(tableVersion)}
                    onSelect={handleSelect}
                />
            )}
        >
            <SelectTableVersionButton
                version={tryParseInt(tableVersion)}
            />
        </Overlay>
    );
}

SelectTableVersion.propTypes = {
    tableId: PropTypes.string
};
