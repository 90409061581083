import React, {JSX} from "react";
import {Image} from "src/pages/Web/components/Image";
// @ts-ignore
import exploreExample from "./assets/explore.png";


export function ExploreExample(): JSX.Element {
    /*
    Every dataset is automatically profiled with basic statistics,
    distributions, correlations, outliers, and more.
     */

    return (
        <div
            className="flex flex-col gap-4"
        >
            <div className="flex-center">
                <div className="text-white/80 text-lg text-center">
                    Exploratory analysis, directly in the web app.
                </div>
            </div>
            <div className="flex-center">
                <div
                    className="w-72 h-64"
                    style={{
                        height: 260,
                        width: 390
                    }}
                >
                    {/* @ts-ignore */}
                    <Image source={exploreExample} bgSize="cover" opacity={0.9}/>
                </div>
            </div>
            <div className="max-w-64 mx-auto">
                <div className="text-white/80 text-sm text-center">
                    Showing empirical column distribution, and theoretical distribution fit.
                </div>
            </div>
        </div>
    );
}
