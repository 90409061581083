import React from "react";
import styled from "styled-components";
import {TitleText} from "src/pages/Web/components/Title.jsx";
import {Button} from "src/primitives/button.jsx";
import {Link} from "react-router-dom";
import {LINKS} from "src/links.js";
import {Badge} from "src/primitives/badge.jsx";


export function GetStarted() {
    return (
        <Wrapper className="m-py-xl w-full border-t border-white/5">
            <div className="px-8 w-192 max-w-full mx-auto">
                <div className="flex flex-col gap-12 max-sm:gap-6">
                    <div>
                        <Badge theme="orangeOutline">Free during beta</Badge>
                    </div>
                    <div>
                        <TitleText>Get started today</TitleText>
                    </div>

                    <div className="flex items-center gap-4">
                        <Link to={LINKS.SIGN_IN}>
                            <Button theme="white">
                                Sign in
                            </Button>
                        </Link>
                        <Link to={LINKS.SIGN_UP}>
                            <Button theme="outline">
                                Sign up
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  // background: linear-gradient(to top, #1c1e20, var(--bg-dark-primary));
  background: linear-gradient(to top, rgba(255, 255, 255, 0.025),  rgba(255, 255, 255, 0.001));
`;
