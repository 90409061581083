import React, {useState} from "react";
import PropTypes from "prop-types";
import {Spinner} from "src/primitives/spinner.jsx";
import {useGetPredictionModel} from "src/api/predictionModels/useGetPredictionModel.js";
import {usePredict} from "src/api/predictionModels/usePredict.js";
import {Title} from "src/primitives/title.jsx";
import {Button} from "src/primitives/button.jsx";
import {formatNumber} from "src/utils/formatting.js";
import {useGetUserApiKeys} from "src/api/users/useUserApiKeys.js";
import {getWorkspaceId} from "src/utils/urlVariables/workspace.js";
import {FeaturesForm} from "./components/FeatureForm/index.jsx";
import {PredictionUrl} from "./components/PredictionUrl/index.jsx";
import {PredictionRequest} from "./components/PredictionRequest/index.jsx";


export function ModelPreview({modelId, tableId}) {
    const workspaceId = getWorkspaceId();
    const {apiKeys} = useGetUserApiKeys(workspaceId);
    const key = apiKeys[0] ? apiKeys[0].value : "$API_KEY";

    const [featureData, setFeatureData] = useState({});
    const {model, loading} = useGetPredictionModel(modelId);
    const [predict, {loading: loadingPredictions, result: predictions}] = usePredict(modelId);

    if (loading) {
        return (
            <div>
                <Spinner/>
            </div>
        );
    }

    const modelFeatures = model.settings.features;

    return (
        <div>
            <div className="flex flex-col gap-8">
                <div className="w-112">
                    <Title size="small">
                        Try out the model
                    </Title>
                    <p className="text-sm mb-4 text-neutral-900">
                        Select values for the features and press predict.
                    </p>
                    <FeaturesForm
                        tableId={tableId}
                        features={modelFeatures}
                        onChange={setFeatureData}
                    />
                </div>

                <div className="flex gap-4 items-center justify-between w-112">
                    <Button
                        isLoading={loadingPredictions}
                        onClick={() => predict([featureData])}
                        isDisabled={!featureData}
                        theme="orange"
                    >
                        Predict
                    </Button>
                    <div className="font-bold monospace">
                        {predictions ? formatNumber(predictions[0]) : ""}
                    </div>
                </div>
                <div>
                    <Title size="small">
                        Prediction request
                    </Title>
                    <p className="text-sm mb-4 text-neutral-900">
                        You can do a GET request with the URL below (or visit in the browser) to get a prediction.
                    </p>
                    <PredictionUrl
                        modelId={modelId}
                        apiKey={key}
                        features={modelFeatures}
                        featureData={featureData}
                    />
                </div>
                <div>
                    <Title size="small">
                        Batch request
                    </Title>
                    <p className="text-sm mb-4 text-neutral-900">
                        Make batch predictions by sending a POST request to the predict endpoint.
                    </p>
                    <PredictionRequest
                        modelId={modelId}
                        apiKey={key}
                        features={modelFeatures}
                        featureData={featureData}
                    />
                </div>
            </div>
        </div>
    );
}

ModelPreview.propTypes = {
    modelId: PropTypes.string.isRequired,
    tableId: PropTypes.string.isRequired
};
