/* eslint-disable max-len */

interface QaItem {
    id: string;
    titleColor: string;
    question: string;
    answer: string;
}

export const qaItems: QaItem[] = [
    /*
    {
        id: "another-data-product",
        titleColor: "text-lime-400/55",
        question: "Another data product, really?",
        answer: `
    Yes, sir.
    The ambition is to create something that takes the simplicity of a spreadsheet and mixes it with the power of a data warehouse.
    Hence we call it; "the lightweight data warehouse".
    `
    },
     */
    {
        id: "what-is-this",
        titleColor: "text-lime-400/55",
        question: "What is this?",
        answer: `
        rho is a super simple database, built for data scientists.
        It's the easiest way to store and manage datasets in the cloud, with absolutely no overhead.
        You can store and retrieve datasets with just one line of code.
        On top of that, all datasets are automatically analyzed with basic statistics, distributions, correlations, outliers, and more.
        `
    },
    {
        id: "who-is-this-for",
        titleColor: "text-orange-400/55",
        question: "Who is this for?",
        answer: `
    Maybe you are a Snowflake expert, but want to do some quick analysis that you can share with other team members.
    Or you are Google Sheet power user, but want something a bit more robust, and with better Python-integration.
    Perhaps you are developing a hobby project and could use a hosted database with minimal overhead.
    `
    }
];
