import React from "react";
import PropTypes from "prop-types";


export function ColumnIllustrationError({columnInfo, stats}) {
    console.warn("Failed to render column illustration: ", columnInfo);
    console.warn("Stats: ", stats);
    return (
        <div className="w-full h-full flex flex-col justify-center items-center">
            <div className="text-center px-4 py-2 text-neutral-500">
                <p>
                    Failed to render column
                </p>
            </div>
        </div>
    );
}

ColumnIllustrationError.propTypes = {
    stats: PropTypes.object,
    columnInfo: PropTypes.shape({
        name: PropTypes.string
    })
};
