import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {cva} from "cva";
import {Tooltip} from "react-tooltip";
import {nanoId} from "src/utils/id.js";


const wrapperClasses = cva({
    base: "rounded-lg group transition-transform",
    variants: {
        size: {
            xxs: "w-4 h-4 text-sm",
            xs: "w-6 h-6 text-base",
            small: "w-8 h-8 text-lg",
            medium: "w-10 h-10 text-xl",
            large: "w-12 h-12 text-2xl",
            xl: "w-12 h-12 text-3xl",
            auto: "w-full h-full"
        },
        flip: {
            no: "rotate-0",
            yes: "rotate-180"
        }
    },
    defaultVariants: {
        size: "medium",
        flip: "no"
    }
});

export function IconWrapper({
    itemId,
    children,
    icon,
    onClick,
    onMouseDown,
    size,
    clickable,
    isActive,
    style,
    thickness,
    fillColor,
    tooltip,
    tooltipProps,
    flip
}) {
    if (tooltip && !itemId) {
        // eslint-disable-next-line no-param-reassign
        itemId = nanoId();
    }

    const classProps = {size, flip: flip ? "yes" : "no"};
    const isClickable = clickable || !!onClick || !!onMouseDown;
    return (
        <>
            <Wrapper
                id={itemId}
                onClick={onClick}
                onMouseDown={onMouseDown}
                $clickable={isClickable}
                $active={isActive}
                $thickness={thickness}
                $fillColor={fillColor}
                className={wrapperClasses(classProps)}
                style={style}
            >
                {children || icon}
            </Wrapper>
            {tooltip && (
                <Tooltip
                    anchorSelect={`#${itemId}`}
                    content={tooltip}
                    style={{zIndex: 10}}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...tooltipProps}
                />
            )}
        </>
    );
}

IconWrapper.propTypes = {
    children: PropTypes.node,
    icon: PropTypes.node,
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    size: PropTypes.oneOf(["xxs", "xs", "small", "medium", "large", "xl", "auto"]),
    clickable: PropTypes.bool,
    isActive: PropTypes.bool,
    style: PropTypes.object,
    thickness: PropTypes.number,
    fillColor: PropTypes.string,
    itemId: PropTypes.string,
    flip: PropTypes.bool,
    tooltip: PropTypes.node,
    tooltipProps: PropTypes.object
};

IconWrapper.defaultProps = {
    tooltipProps: {
        delayShow: 400,
        delayHide: 100,
        place: "bottom"
    }
};

const Wrapper = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  transition-property: all;
  transition-duration: 200ms;
  ${({$clickable}) => $clickable ? `
    cursor: pointer;
    &:hover {        
      background-color: rgba(0, 0, 0, 0.1);
    }
  ` : ""};
  ${({$active}) => $active ? `
    background-color: rgba(0, 0, 0, 0.1);
  ` : ""};
  ${({$thickness}) => $thickness ? `
    & svg {
        stroke-width: ${$thickness}px;
    }
  ` : ""};
  ${({$fillColor}) => $fillColor ? `
    & svg {
        fill: ${$fillColor};
    }
  ` : ""};
`;
