/* eslint-disable max-len */

export const Features = {
    PYTHON_SDK: "PYTHON_SDK",
    AUTOMATED_ANALYSIS: "AUTOMATED_ANALYSIS",
    VERSIONING: "VERSIONING",
    COLLABORATION: "COLLABORATION",
    PREDICTION_MODELS: "PREDICTION_MODELS",
    BYOB: "BYOB"
};

export const featuresContent = {
    [Features.PYTHON_SDK]: {
        title: "Effortless Python Integration",
        description: "The Python SDK is built to work natively with dataframes. Store and retrieve datasets with one line of code.",
        longDescription: "Integrate Rho seamlessly into your existing data science workflow with our intuitive Python SDK.  Effortlessly store, retrieve, and manage datasets with just a few lines of code.  Rho automatically handles schema changes and updates, freeing you from tedious data engineering tasks.  Plus, enjoy built-in Pandas DataFrame compatibility for maximum efficiency."
    },
    [Features.AUTOMATED_ANALYSIS]: {
        title: "Automated Analysis",
        description: "Uploaded datasets are profiled and analyzed, offering key statistics and quick insights into the dataset.",
        longDescription: "Gain instant insights into your data with Rho's automated analysis capabilities.  Upon upload, Rho automatically profiles your datasets, identifying key statistics, potential issues, and hidden patterns.  This accelerates your exploratory data analysis and helps you make informed decisions faster."
    },
    [Features.VERSIONING]: {
        title: "Versioning",
        description: "Keep multiple versions of your dataset. Ensure reproducibility and maintain a clear history of your data evolution.",
        longDescription: "Never lose track of your data's history. Rho's built-in versioning system allows you to effortlessly track changes to your datasets, revert to previous versions, and maintain a complete audit trail.  Ensure reproducibility in your experiments and maintain data integrity with ease."
    },
    [Features.COLLABORATION]: {
        title: "Sharing & Collaboration",
        description: "Share specific files or folders with friends and colleagues, or create a public link to share your data with the world.",
        longDescription: "Say goodbye to outdated spreadsheets and endless email chains.  Rho's collaborative features empower you to share datasets, work on projects together in real-time, and maintain a single source of truth for your data.  Streamline your teamwork and accelerate your data-driven discoveries."
    },
    [Features.PREDICTION_MODELS]: {
        title: "Build prototype models",
        description: "Quickly train and evaluate prediction models without complex infrastructure. Rho streamlines the prototyping process, accelerating your machine-learning development.",
        longDescription: "Accelerate your machine learning development with Rho's streamlined model building capabilities.  Quickly train and evaluate prediction models without the need for complex infrastructure or extensive setup.  Rho simplifies the prototyping process, allowing you to iterate faster and bring your ML projects to life sooner."
    },
    [Features.BYOB]: {
        title: "BYOB: Bring Your Own Backend",
        description: "For increased control over data security and regulatory compliance (like GDPR), you can connect your own hosted database (e.g., PostgreSQL, MySQL) to Rho. This allows you to leverage Rho's features while maintaining data within your existing infrastructure.",
        longDescription: "Maintain complete control over your data security and compliance with Rho's Bring Your Own Backend (BYOB) feature.  Connect your own hosted database (such as PostgreSQL or MySQL) to Rho and leverage its powerful features while keeping your data within your existing infrastructure.  This ensures compliance with regulations like GDPR and provides peace of mind for sensitive data projects."
    }
};
