import React from "react";
import {cva} from "cva";
import {formatTimestamp} from "src/utils/formatting.js";
import {TableVersion} from "src/types/tableVersion";

export const tableVersionItemClasses = cva({
    base: "rounded-md py-1 px-2",
    variants: {
        state: {
            normal: "hover:bg-black-3",
            selected: "bg-green-100/50 text-green-700"
        }
    },
    defaultVariants: {
        state: "normal"
    }
});

interface TableVersionListItemProps {
    version: TableVersion;
    onSelect: (e : React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    isSelected: boolean;
}

export const TableVersionListItem = ({version, onSelect, isSelected}: TableVersionListItemProps) => {
    return (
        <button
            type="button"
            onClick={onSelect}
            disabled={isSelected}
            className={tableVersionItemClasses({state: isSelected ? "selected" : "normal"})}
        >
            <div
                className="flex items-center text-left"
            >
                <span className="basis-16 grow-0 shrink-0">
                    v{version.version}
                </span>
                <span className="grow">
                    {formatTimestamp(version.createdAt)}
                </span>
                <span>
                    {version.totalRows}
                </span>
            </div>
        </button>
    );
};
