import React from "react";
import {buttonStyle} from "src/primitives/button.jsx";
import {FiChevronDown} from "react-icons/fi";


interface SelectTableVersionButtonProps {
    version: number;
}

export function SelectTableVersionButton({version}: SelectTableVersionButtonProps) {
    return (
        <button
            type="button"
            className={buttonStyle({theme: "outlineBlack", size: "xs"})}
        >
            <div className="flex items-center mr-[-6px] gap-2">
                <span>
                    v{version}
                </span>
                <div className="w-4 h-4 flex-center text-[15px]">
                    <FiChevronDown/>
                </div>
            </div>
        </button>
    );
}
