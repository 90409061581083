import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {cva} from "cva";
import {IconWrapper} from "src/primitives/Icon.jsx";


export const buttonStyle = cva({
    // eslint-disable-next-line max-len
    base: "relative inline-flex items-center justify-center gap-x-2 font-semibold rounded-lg border transition disabled:opacity-60 disabled:cursor-not-allowed",
    variants: {
        theme: {
            primary: "bg-blue-600 hover:bg-blue-700 text-white border-transparent",
            danger: "bg-red-500 hover:bg-red-600 text-white border-transparent",
            secondary: "bg-neutral-800 hover:bg-black text-white border-transparent",
            black: "bg-black hover:bg-neutral-900 text-white border-transparent",
            white: "bg-white/90 hover:bg-gray-200/95 text-gray-950 border-transparent",
            gray: "bg-neutral-300 hover:bg-neutral-400 text-neutral-700 border-transparent",
            green: "bg-lime-600 hover:bg-lime-700 text-white border-transparent",
            orange: "bg-orange-600 hover:bg-orange-700 text-white border-transparent",
            outline: "bg-transparent hover:bg-white/5 text-white border-white disabled:hover:bg-transparent",
            // eslint-disable-next-line max-len
            outlineBlack: "bg-transparent hover:bg-black/5 text-neutral-950 border-neutral-950 disabled:hover:bg-transparent"

        },
        size: {
            xs: "text-xs py-1 px-4",
            small: "text-sm py-1.5 px-6 min-w-18",
            medium: "text-base py-3 px-10 min-w-36"
        }
    },
    defaultVariants: {
        theme: "primary",
        size: "small"
    }
});

const spinnerClasses = cva({
    base: "animate-spin inline-block border-[3px] border-current border-t-transparent text-white rounded-full",
    variants: {
        size: {
            xs: "w-3 h-3 !border-[2px]",
            small: "w-5 h-5",
            medium: "w-6 h-6"
        }
    },
    defaultVariants: {
        size: "small"
    }
});

function Spinner({size}) {
    return (
        <span
            className={spinnerClasses({size})}
            role="status"
            aria-label="loading"
        >
            <span className="sr-only">Loading...</span>
        </span>
    );
}

Spinner.propTypes = {
    size: PropTypes.string
};

export function Button({
    type,
    children,
    theme,
    size,
    onClick,
    isLoading,
    isDisabled,
    style
}) {
    return (
        <button
            className={buttonStyle({theme, size})}
            onClick={onClick}
            disabled={isLoading || isDisabled}
            /* eslint-disable-next-line react/button-has-type */
            type={type}
            style={style}
        >
            <div style={{opacity: isLoading ? 0 : 1}}>
                {children}
            </div>
            {isLoading && (
                <SpinnerWrapper>
                    <Spinner size={size}/>
                </SpinnerWrapper>
            )}
        </button>
    );
}

const themes = [
    "primary", "danger", "secondary", "black", "white", "gray", "green", "orange", "outline", "outlineBlack"
];

Button.propTypes = {
    type: PropTypes.oneOf(["button", "submit", "reset"]),
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    theme: PropTypes.oneOf(themes),
    size: PropTypes.oneOf(["xs", "small", "medium"]),
    style: PropTypes.object
};

Button.defaultProps = {
};

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function IconButton({theme, size, isDisabled, isLoading, icon, text, onClick}) {
    const style = text ? {marginLeft: -6} : {marginLeft: -6, marginRight: -6};
    return (
        <Button
            theme={theme}
            size={size}
            isDisabled={isDisabled}
            isLoading={isLoading}
            onClick={onClick}
        >
            <div className="flex items-center gap-2" style={style}>
                <IconWrapper icon={icon} size="xxs" style={{}}/>
                {text && (
                    <span>{text}</span>
                )}
            </div>
        </Button>
    );
}

IconButton.propTypes = {
    onClick: PropTypes.func,
    theme: PropTypes.oneOf(themes),
    size: PropTypes.string,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    icon: PropTypes.element,
    text: PropTypes.node
};

IconButton.defaultProps = {
    theme: "outlineBlack",
    size: "xs"
};
