import React from "react";
import {SidebarDrawer} from "src/primitives/drawer/index.jsx";
import {useCurrentTableId} from "src/pages/Home/contexts/current_table_context.jsx";
import {useEditColumnsState} from "./states.js";
import {EditColumns} from "./components/EditColumns/index.jsx";


export function EditColumnsSidebar() {
    const tableId = useCurrentTableId();
    const [isOpen, close] = useEditColumnsState((state) => [state.isOpen, state.close]);

    return (
        <SidebarDrawer
            isOpen={isOpen}
            close={close}
            size={480}
            title={(
                <div>Edit columns</div>
            )}
        >
            <EditColumns tableId={tableId}/>
        </SidebarDrawer>

    );
}
