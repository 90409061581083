import React, {JSX} from "react";
import {Terminal} from "src/primitives/Terminal";


export function CreateExample(): JSX.Element {
    return (
        <div>
            <Terminal
                hideTopBar={true}
                language="python"
                code={`
# %pip install rho-store
import pandas as pd
from rho_store import RhoClient

client = RhoClient(api_key="$API_KEY")
data = pd.DataFrame({"val": [i for i in range(10)]})

# Create new table and store some data
# schema is automatically detected and created
table = client.store_df(data)  

# visit table in browser
print(table.url)
                        `.trim()}
            />
        </div>
    );
}
